<template>
  <div class="">
    <transition name="qvover">
      <div
        v-if="showQuickview"
        class="
          backdrop-blur backdrop-filter
          fixed
          inset-0
          bg-gray-900 bg-opacity-20
          z-30
          md:block
        "
        aria-hidden="true"
      />
    </transition>

    <transition name="qview">
      <div v-if="showQuickview">
        <TheProductQuickview />
      </div>
    </transition>
    <TopNav />
    <transition name="thecart">
      <div v-if="showCart">
        <TheCart />
      </div>
    </transition>
    <transition name="cartover">
      <div
        v-if="showCart"
        class="
          absolute
          inset-0
          z-30
          bg-gray-500 bg-opacity-75
          transition-opacity
        "
        aria-hidden="true"
      />
    </transition>

    <transition name="mmenu">
      <MobileMenu2 />
    </transition>

    <transition
      v-if="openMobileMenu"
      name="mmover"
    >
      <div
        class="fixed inset-0 bg-black bg-opacity-25 z-30"
        aria-hidden="true"
      />
    </transition>

    <router-view />
    <Footer2 />
    <!-- <TheFooter /> -->
  </div>
</template>
<script>
import TheProductQuickview from "@/components/Products/TheProductQuickview";
import Footer2 from "@/components/Footer2";
import { mapState, mapActions } from "vuex";
//import MobileMenu from "@/components/Navigation/MobileMenu";
import MobileMenu2 from "@/components/Navigation/MobileMenu2";
import TopNav from "@/components/Navigation/TopNav/index";
import TheCart from "@/components/ecommerce/TheCart";
//import TheFooter from "@/components/Footer/index";

export default {
  components: {
    TopNav,
    //MobileMenu,
    Footer2,
    //TheFooter,
    TheCart,
    TheProductQuickview,
    MobileMenu2,
  },
  methods: {
    ...mapActions(["toggleQuickview"]),
  },
  computed: {
    ...mapState(["showCart", "showQuickview", "openMobileMenu"]),
  },
  mounted() {
    this.$store.dispatch("getProducts");
    // this.$store.dispatch("setCurrentCatt");
  },
};
</script>

<style scoped>
.v-enter-active {
  @apply transition;
  @apply ease-in-out;
  @apply duration-300;
  @apply transform;
}
.v-enter,
.v-leave-to {
  /* @apply opacity-0; */
  @apply -translate-x-full;
}
.v-enter-to,
.v-leave {
  /* @apply opacity-100; */
  @apply translate-x-0;
}
.v-leave-active {
  @apply transition;
  @apply ease-in-out;
  @apply duration-300;
  @apply transform;
}

.qview-enter-active {
  @apply ease-linear;
  @apply duration-300;
  @apply delay-300;
}
.qview-enter,
.qview-leave-to {
  @apply opacity-0;
  @apply translate-y-4;
  @apply md:translate-y-0;
  @apply md:scale-95;
}
.qview-enter-to,
.qview-leave {
  @apply opacity-100;
  @apply translate-y-0;
  @apply md:scale-100;
}
.qview-leave-active {
  @apply ease-in;
  @apply duration-200;
}

/* .qview-enter-active{
  @apply ease-out;
  @apply duration-100;
   @apply delay-300;

}
.qview-enter, .qview-leave-to {
  @apply opacity-0;

}
.qview-enter-to, .qview-leave {
  @apply opacity-100;
}
.qview-leave-active {
  @apply ease-in;
  @apply duration-200;
} */

.qvover-enter-active {
  @apply ease-out;
  @apply duration-300;
}
.qvover-enter,
.qvover-leave-to {
  @apply opacity-0;
}
.qvover-enter-to,
.qvover-leave {
  @apply opacity-100;
}
.qvover-leave-active {
  @apply ease-in;
  @apply duration-200;
}
.cartover-enter-active,
.cartover-leave-active {
  @apply ease-in-out;
  @apply duration-500;
}
.cartover-enter,
.cartover-leave-to {
  @apply opacity-0;
}
.cartover-enter-to,
.cartover-leave {
  @apply opacity-100;
}

.thecart-enter-active,
.thecart-leave-active {
  @apply transform;
  @apply transition;
  @apply ease-in-out;
  @apply duration-500;
  @apply sm:duration-700;
}
.thecart-enter,
.thecart-leave-to {
  @apply translate-x-full;
}
.thecart-enter-to,
.thecart-leave {
  @apply translate-x-0;
}
.mmover-enter-active,
.mmover-leave-active {
  @apply transition-opacity;
  @apply ease-linear;
  @apply duration-300;
}
.mmover-enter,
.mmover-leave-to {
  @apply opacity-0;
}
.mmover-enter-to,
.mmover-leave {
  @apply opacity-100;
}
.mmenu-enter-active,
.mmenu-leave-active {
  @apply transition;
  @apply ease-in-out;
  @apply duration-300;
  @apply transform;
}
.mmenu-enter,
.mmenu-leave-to {
  @apply -translate-x-full;
}
.mmenu-enter-to,
.mmenu-leave {
  @apply translate-x-0;
}
</style>

<template>
  <!--
  This example requires require('@tailwindcss/forms'),
-->
  <div class="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
    <TheContactHero />
    <div class="relative max-w-xl mx-auto">
      <svg
        class="absolute left-full transform translate-x-1/2"
        width="404"
        height="404"
        fill="none"
        viewBox="0 0 404 404"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="85737c0e-0916-41d7-917f-596dc7edfa27"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="404"
          fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
        />
      </svg>
      <svg
        class="absolute right-full bottom-0 transform -translate-x-1/2"
        width="404"
        height="404"
        fill="none"
        viewBox="0 0 404 404"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="85737c0e-0916-41d7-917f-596dc7edfa27"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="404"
          fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
        />
      </svg>
      <div class="text-center">
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          Contact form
        </h2>
        <p class="mt-4 text-lg leading-6 text-gray-500">
          We usually respond between 1-3 business days
        </p>
      </div>
      <div v-if="submitted">
        <TheSubmittedAlert />
      </div>
      <div
        v-if="!submitted"
        class="mt-12"
      >
        <form
          novalidate="true"
          class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          @submit.prevent="submit"
        >
          <div>
            <label
              for="first-name"
              class="block text-sm font-medium text-gray-700"
            >First name</label>
            <div class="mt-1">
              <input
                id="first-name"
                v-model="firstName"
                type="text"
                name="first-name"
                autocomplete="given-name"
                class="
                  py-3
                  px-4
                  block
                  w-full
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  border-gray-300
                  rounded-md
                "
              >
            </div>
          </div>
          <div>
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >Last name</label>
            <div class="mt-1">
              <input
                id="last-name"
                v-model="lastName"
                type="text"
                name="last-name"
                autocomplete="family-name"
                class="
                  py-3
                  px-4
                  block
                  w-full
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  border-gray-300
                  rounded-md
                "
              >
            </div>
          </div>
          <div class="sm:col-span-2">
            <label
              for="email"
              class="block text-sm font-medium text-gray-700"
            >Email</label>
            <div class="mt-1">
              <input
                id="email"
                v-model="email"
                name="email"
                type="email"
                autocomplete="email"
                class="
                  py-3
                  px-4
                  block
                  w-full
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  border-gray-300
                  rounded-md
                "
              >
            </div>
          </div>
          <div class="sm:col-span-2">
            <label
              for="phone-number"
              class="block text-sm font-medium text-gray-700"
            >Phone Number</label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 flex items-center">
                <label
                  for="country"
                  class="sr-only"
                >Country</label>
                <select
                  id="country"
                  name="country"
                  class="
                    h-full
                    py-0
                    pl-4
                    pr-8
                    border-transparent
                    bg-transparent
                    text-gray-500
                    focus:ring-indigo-500 focus:border-indigo-500
                    rounded-md
                  "
                >
                  <option>US</option>
                  <option>CA</option>
                  <option>EU</option>
                </select>
              </div>
              <input
                id="phone-number"
                v-model="phone"
                type="text"
                name="phone-number"
                autocomplete="tel"
                class="
                  py-3
                  px-4
                  block
                  w-full
                  pl-20
                  focus:ring-indigo-500 focus:border-indigo-500
                  border-gray-300
                  rounded-md
                "
                placeholder="+1 (555) 987-6543"
              >
            </div>
          </div>
          <div class="sm:col-span-2">
            <label
              for="message"
              class="block text-sm font-medium text-gray-700"
            >Message</label>
            <div class="mt-1">
              <textarea
                id="message"
                v-model="message"
                name="message"
                rows="4"
                class="
                  py-3
                  px-4
                  block
                  w-full
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  border border-gray-300
                  rounded-md
                "
              />
            </div>
          </div>
          <div class="sm:col-span-2">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                <button
                  type="button"
                  :class="agree ? 'bg-indigo-600' : 'bg-gray-200'"
                  class="
                    relative
                    inline-flex
                    flex-shrink-0
                    h-6
                    w-11
                    border-2 border-transparent
                    rounded-full
                    cursor-pointer
                    transition-colors
                    ease-in-out
                    duration-200
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                  role="switch"
                  aria-checked="false"
                  @click="agree = !agree"
                >
                  <span class="sr-only">Agree to policies</span>
                  <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                  <span
                    :class="agree ? 'translate-x-5' : 'translate-x-0'"
                    aria-hidden="true"
                    class="
                      inline-block
                      h-5
                      w-5
                      rounded-full
                      bg-white
                      shadow
                      transform
                      ring-0
                      transition
                      ease-in-out
                      duration-200
                    "
                  />
                </button>
              </div>
              <div class="ml-3">
                <p class="text-base text-gray-500">
                  By selecting this, you agree to the
                  <a
                    href="#"
                    class="font-medium text-gray-700 underline"
                  >Privacy Policy</a>
                  and
                  <a
                    href="#"
                    class="font-medium text-gray-700 underline"
                  >Cookie Policy</a>.
                </p>
              </div>
            </div>
          </div>
          <div v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul
              v-for="(error, index) in errors"
              :key="index"
            >
              <li>
                {{ error }}
              </li>
            </ul>
          </div>
          <div class="sm:col-span-2">
            <button
              type="submit"
              class="
                w-full
                inline-flex
                items-center
                justify-center
                px-6
                py-3
                border border-transparent
                rounded-md
                shadow-sm
                text-base
                font-medium
                text-white
                bg-indigo-600
                hover:bg-indigo-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
              "
            >
              Let's talk
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import TheSubmittedAlert from "@/Pages/Contact/TheSubmittedAlert";
import TheContactHero from "@/Pages/Contact/TheContactHero";
export default {
  name: "Contact",
  components: {
    TheContactHero,
    TheSubmittedAlert,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      errors: [],
      agree: false,
      submitted: false,
      phones: [22, 333, 444],
    };
  },
  methods: {
    submit() {
      if (
        (this.firstName,
        this.lastName,
        this.email,
        this.phone,
        this.message,
        this.agree)
      ) {
        this.submitted = true;
        this.errors = [];
        const db = firebase.firestore();
        db.collection("contactEmails")
          .add({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            message: this.message,
          })
          .then((res) => {
            console.log(res);
          });
      }
      if (!this.agree) {
        this.errors.push(
          "In order to contact us, you have to agree to our policies"
        );
      }
      if (!this.firstName) {
        this.errors.push("First name required.");
      }
      if (!this.lastName) {
        this.errors.push("Last Name required.");
      }
      if (!this.email) {
        this.errors.push("Email required.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Valid email required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style></style>

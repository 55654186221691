<template>
  <Product />
</template>

<script>
import Product from "@/components/Products/index";
export default {
  components: {
    Product,
  },
};
</script>

<style></style>

<template>
  <Categories />
</template>

<script>
import Categories from "@/components/Categories/index";
export default {
  components: {
    Categories,
  },
};
</script>

<style></style>

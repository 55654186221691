<template>
  <div>
    <TheHeader />
    <div v-if="showAddForm">
      <button
        type="button"
        class="
          absolute
          top-80
          right-16
          inline-flex
          items-center
          px-4
          py-2
          border border-transparent
          text-sm
          font-medium
          rounded-md
          shadow-sm
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
        "
        @click="showAddForm = false"
      >
        Back to table
      </button>
    </div>
    <div v-if="!showAddForm">
      <button
        type="button"
        class="
          absolute
          top-80
          right-16
          inline-flex
          items-center
          p-3
          border border-transparent
          rounded-full
          shadow-sm
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
        "
        @click="showAddForm = true"
      >
        <IconBase
          class="h-6 w-6"
          fill="none"
          view-box="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <IconOutlinePlusSm />
        </IconBase>
      </button>
    </div>

    <div v-if="!showAddForm">
      <ProductsTable />
    </div>

    <div v-if="showAddForm">
      <TheAddProForm />
    </div>
  </div>
</template>

<script>
import TheHeader from "@/Pages/Admin/Products/ProductsTable/TheHeader";
import IconBase from "@/components/Icons/IconBase";
import IconOutlinePlusSm from "@/components/Icons/IconOutlinePlusSm";
import TheAddProForm from "@/Pages/Admin/Products/TheAddProForm";
import ProductsTable from "@/Pages/Admin/Products/ProductsTable";
export default {
  name: "AdminProducts",
  components: {
    IconBase,
    IconOutlinePlusSm,
    TheAddProForm,
    ProductsTable,
    TheHeader,
  },
  data() {
    return {
      showAddForm: false,
    };
  },
};
</script>

<style>
</style>
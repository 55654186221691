<template>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M18 12H6"
  />
</template>

<script>
export default {};
</script>

<style></style>

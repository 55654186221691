<template>
  <!--
      Mobile filter dialog

      Off-canvas filters for mobile, show/hide based on off-canvas filters state.
    -->
  <div
    class="fixed inset-0 flex z-40 lg:hidden"
    role="dialog"
    aria-modal="true"
  >
    <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
    <transition>
      <div
        class="fixed inset-0 bg-black bg-opacity-25"
        aria-hidden="true"
      ></div>
    </transition>
    <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "translate-x-full"
      -->
    <div
      v-click-outside="toggleMobileFilterMenu"
      class="
        ml-auto
        relative
        max-w-xs
        w-full
        h-full
        bg-white
        shadow-xl
        py-4
        pb-12
        flex flex-col
        overflow-y-auto
      "
    >
      <div class="px-4 flex items-center justify-between">
        <h2 class="text-lg font-medium text-gray-900">Filters</h2>
        <button
          @click="toggleMobileFilterMenu"
          type="button"
          class="
            -mr-2
            w-10
            h-10
            bg-white
            p-2
            rounded-md
            flex
            items-center
            justify-center
            text-gray-400
          "
        >
          <span class="sr-only">Close menu</span>

          <!-- Heroicon name: outline/x -->
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <!-- Filters -->
      <form class="mt-4 border-t border-gray-200">
        <h3 class="sr-only">Categories</h3>
        <ul role="list" class="font-medium text-gray-900 px-2 py-3">
          <li
            v-for="(item, index) in categoryItems"
            :key="index"
            @click="item.atClick"
            class="block px-2 py-3 cursor-pointer"
          >
            <a>{{ item.text }}</a>
          </li>
        </ul>

        <!-- the mobile color, category, size filter -->
        <!-- <TheMobileColorCategorySizeFilter /> -->
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      categoryItems: [
        { text: "All Products", atClick: this.setShowAllProducts },
        { text: "Totes", atClick: this.setShowTotes },
        { text: "Backpacks", atClick: this.setShowBackpacks },
        { text: "Travel Bags", atClick: this.setShowTravelBags },
        { text: "Hip Bags", atClick: this.setShowHipBags },
        { text: "Laptop Sleeves", atClick: this.setShowLaptopSleeves },
      ],
    };
  },
  methods: {
    ...mapMutations(["toggleMobileFilterMenu"]),
    ...mapActions([
      "setShowAllProducts",
      "setShowTotes",
      "setShowBackpacks",
      "setShowTravelBags",
      "setShowHipBags",
      "setShowLaptopSleeves",
    ]),
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  @apply transition-opacity;
  @apply ease-linear;
  @apply duration-300;
}
.v-enter,
.v-leave-to {
  @apply opacity-0;
}
.v-enter-to,
.v-leave {
  @apply opacity-100;
}
</style>

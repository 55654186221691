<template>
  <div
    class="fixed inset-0 overflow-hidden z-50"
    aria-labelledby="slide-over-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="absolute inset-0 overflow-hidden">
      <!-- Background overlay, show/hide based on slide-over state. 
      placed in app.vue

      Entering: "ease-in-out duration-500"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in-out duration-500"
        From: "opacity-100"
        To: "opacity-0"
   
      <div
        class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      /> -->

      <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
        <!--
        Slide-over panel, show/hide based on slide-over state.

        Entering: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-full"
          To: "translate-x-0"
        Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
          From: "translate-x-0"
          To: "translate-x-full"
      -->
        <div class="w-screen max-w-md">
          <div
            v-click-outside="toggleShowCart"
            class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
          >
            <div class="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
              <div class="flex items-start justify-between">
                <h2
                  id="slide-over-title"
                  class="text-lg font-medium text-gray-900"
                >
                  Shopping cart
                </h2>
                <div class="ml-3 h-7 flex items-center">
                  <button
                    type="button"
                    class="-m-2 p-2 text-gray-400 hover:text-gray-500"
                    @click="toggleShowCart"
                  >
                    <span class="sr-only">Close panel</span>
                    <IconBase
                      view-box="0 0 24 24"
                      fill="none"
                      class="h-6 w-6"
                      aria-hidden="true"
                    >
                      <IconOutlineX />
                    </IconBase>
                  </button>
                </div>
              </div>

              <div class="mt-8">
                <div class="flow-root">
                  <ul
                    role="list"
                    class="-my-6 divide-y divide-gray-200"
                  >
                    <div
                      v-for="item in cart"
                      :key="item.name"
                    >
                      <li class="py-6 flex">
                        <div
                          class="
                            flex-shrink-0
                            w-24
                            h-24
                            border border-gray-200
                            rounded-md
                            overflow-hidden
                          "
                        >
                          <img
                            :src="item.image"
                            alt=""
                          >
                        </div>

                        <div class="ml-4 flex-1 flex flex-col">
                          <div>
                            <div
                              class="
                                flex
                                justify-between
                                text-base
                                font-medium
                                text-gray-900
                              "
                            >
                              <h3>
                                <a href="#"> {{ item.name }} </a>
                              </h3>
                              <div class="flex gap-x-1">
                                <div>
                                  <p class="ml-3 mr-1.5 text-md">
                                    <span>$</span>{{ item.price | currency }}
                                  </p>
                                </div>
                                <div class="flex item-center">
                                  <p class="mr-1 mt-1 text-xs text-gray-400">
                                    Total:
                                  </p>
                                  <p class="text-sm">
                                    <span>$</span>{{
                                      (item.price * item.quantity) | currency
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="flex justify-between">
                              <p class="mt-1 text-sm text-gray-500">
                                {{ item.brand }}
                              </p>
                            </div>
                          </div>
                          <div class="flex justify-between items-center">
                            <div>
                              <button
                                class="
                                  border-b border-gray-500
                                  text-gray-500 text-sm
                                "
                                @click="cartRemove(item.id)"
                              >
                                remove
                              </button>
                            </div>
                            <div
                              class="
                                flex
                                gap-x-5
                                items-center
                                bg-indigo-500
                                rounded-full
                                p-1
                              "
                            >
                              <button
                                type="button"
                                class="
                                  inline-flex
                                  items-center
                                  p-2
                                  border border-transparent
                                  rounded-full
                                  shadow-sm
                                  text-white
                                  bg-indigo-600
                                  hover:bg-indigo-700
                                  focus:outline-none
                                  focus:ring-2
                                  focus:ring-offset-2
                                  focus:ring-indigo-500
                                "
                                @click="cartDecrease(item.id)"
                              >
                                <IconBase
                                  fill="currentColor"
                                  class="h-5 w-5"
                                  view-box="0 0 20 20"
                                >
                                  <IconSolidMinusSm />
                                </IconBase>
                              </button>
                              <p class="text-white">
                                {{ item.quantity }}
                              </p>
                              <button
                                type="button"
                                class="
                                  inline-flex
                                  items-center
                                  p-2
                                  border border-transparent
                                  rounded-full
                                  shadow-sm
                                  text-white
                                  bg-indigo-600
                                  hover:bg-indigo-700
                                  focus:outline-none
                                  focus:ring-2
                                  focus:ring-offset-2
                                  focus:ring-indigo-500
                                "
                                @click="cartIncrease(item.id)"
                              >
                                <IconBase
                                  view-box="0 0 20 20"
                                  class="h-5 w-5"
                                  fill="currentColor"
                                >
                                  <IconSolidPlusSm />
                                </IconBase>
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>

            <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
              <div
                class="flex justify-between text-base font-medium text-gray-900"
              >
                <p>Subtotal</p>
                <p><span class="">$</span>{{ total | currency }}</p>
              </div>
              <p class="mt-0.5 text-sm text-gray-500">
                Shipping and taxes calculated at checkout.
              </p>
              <div class="mt-6">
                <a
                  v-if="!submitted"
                  href="#"
                  class="
                    flex
                    justify-center
                    items-center
                    px-6
                    py-3
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-base
                    font-medium
                    text-white
                    bg-indigo-600
                    hover:bg-indigo-700
                  "
                  @click="submit"
                >Checkout</a>
                <div v-if="submitted">
                  <button
                    type="button"
                    class="
                      w-full
                      flex
                      gap-x-4
                      justify-center
                      items-center
                      px-6
                      py-3
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-base
                      font-medium
                      text-white
                      bg-indigo-600
                      hover:bg-indigo-700
                      cursor-not-allowed
                    "
                    disabled
                  >
                    <div>
                      <div
                        style="border-top-color: transparent"
                        class="
                          w-5
                          h-5
                          border-4 border-white border-dotted
                          rounded-full
                          animate-spin
                        "
                      />
                    </div>
                    <p>Processing</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/Icons/IconBase";
import IconOutlineX from "@/components/Icons/IconOutlineX";
import IconSolidMinusSm from "@/components/Icons/IconSolidMinusSm";
import IconSolidPlusSm from "@/components/Icons/IconSolidPlusSm";
import axios from "axios";
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  name: "TheCart",
  components: {
    IconBase,
    IconOutlineX,
    IconSolidMinusSm,
    IconSolidPlusSm,
  },
  data() {
    return {
      pk: "pk_test_rf2yfkvjqcSjj91hrweyPa5B",
      sessionId: "",
      submitted: false,
    };
  },
  computed: {
    ...mapState(["cart"]),
    //total price of the whole cart
    total() {
      let total = 0;
      this.cart.forEach((item) => {
        total += item.price * item.quantity;
      });
      return total;
    },
  },
  methods: {
    ...mapActions(["cartDecrease"]),
    ...mapMutations(["toggleShowCart", "cartRemove", "cartIncrease"]),
    submit() {
      this.submitted = true;
      let data = this.cart.map((product) => {
        return { [product.id]: product.quantity };
      });
      data = Object.assign({}, ...data);
      //console.log(data, 'first')
      axios
        .get(
          "https://us-central1-fir-cart-c6633.cloudfunctions.net/CheckoutSession",
          {
            params: {
              products: data,
            },
          }
        )
        .then((res) => {
          console.log('ppppsppps')
            console.log(res);
            console.log(res.data)
            console.log(res.data.id)
        //  this.sessionId = res.data;
         // this.sessionId = res;
          this.sessionId = res.data.id
         // console.log(this.sessionId, 'consoling sessionID')
          const stripe = window.Stripe("pk_test_rf2yfkvjqcSjj91hrweyPa5B");
          stripe.redirectToCheckout({
          //  sessionId: this.sessionId.id,
             sessionId: this.sessionId,
          });
        })
        .catch((err) => {
          console.log(err, "is this the error");
        });
    },
  },
};
</script>
<template>
  <section
    aria-labelledby="category-heading"
    class="pt-24 sm:pt-32 xl:max-w-7xl xl:mx-auto xl:px-8"
  >
    <div
      class="
        px-4
        sm:px-6 sm:flex sm:items-center sm:justify-between
        lg:px-8
        xl:px-0
      "
    >
      <h2
        id="category-heading"
        class="text-2xl font-extrabold tracking-tight text-gray-900"
      >
        Shop by Category
      </h2>
      <!-- <a
        href="#"
        class="
          hidden
          text-sm
          font-semibold
          text-indigo-600
          hover:text-indigo-500
          sm:block
        "
        >Browse all categories<span aria-hidden="true"> &rarr;</span></a
      > -->
    </div>

    <div class="mt-4 flow-root">
      <div class="-my-2">
        <div
          class="
            box-content
            py-2
            relative
            h-80
            overflow-x-auto
            xl:overflow-visible
          "
        >
          <div
            class="
              absolute
              min-w-screen-xl
              px-4
              flex
              space-x-8
              sm:px-6
              lg:px-8
              xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8
            "
          >
            <a
              class="
                cursor-pointer
                relative
                w-56
                h-80
                rounded-lg
                p-6
                flex flex-col
                overflow-hidden
                hover:opacity-75
                xl:w-auto
              "
              @click="goToTotes"
            >
              <span
                aria-hidden="true"
                class="absolute inset-0"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/fir-cart-c6633.appspot.com/o/home-page%2FcategorySection%2Fcotton-bag-with-vegetables-fruits-wooden-table.jpg?alt=media&token=dd0d024a-17a5-4083-a62d-19ddb6cdafd4"
                  alt=""
                  class="w-full h-full object-center object-cover"
                >
              </span>
              <span
                aria-hidden="true"
                class="
                  absolute
                  inset-x-0
                  bottom-0
                  h-2/3
                  bg-gradient-to-t
                  from-gray-800
                  opacity-50
                "
              />
              <span
                class="
                  relative
                  mt-auto
                  text-center text-xl
                  font-bold
                  text-white
                "
              >Totes</span>
            </a>
            <a
              class="
                cursor-pointer
                relative
                w-56
                h-80
                rounded-lg
                p-6
                flex flex-col
                overflow-hidden
                hover:opacity-75
                xl:w-auto
              "
              @click="goToBackpacks"
            >
              <span
                aria-hidden="true"
                class="absolute inset-0"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/fir-cart-c6633.appspot.com/o/home-page%2FcategorySection%2Fjosh-appel-_k4NMSKwZDU-unsplash.jpg?alt=media&token=0829e270-43ee-4943-ac47-2fc0d076894f"
                  alt=""
                  class="w-full h-full object-center object-cover"
                >
              </span>
              <span
                aria-hidden="true"
                class="
                  absolute
                  inset-x-0
                  bottom-0
                  h-2/3
                  bg-gradient-to-t
                  from-gray-800
                  opacity-50
                "
              />
              <span
                class="
                  relative
                  mt-auto
                  text-center text-xl
                  font-bold
                  text-white
                "
              >Backpacks</span>
            </a>

            <a
              class="
                cursor-pointer
                relative
                w-56
                h-80
                rounded-lg
                p-6
                flex flex-col
                overflow-hidden
                hover:opacity-75
                xl:w-auto
              "
              @click="goToTravelBags"
            >
              <span
                aria-hidden="true"
                class="absolute inset-0"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/fir-cart-c6633.appspot.com/o/home-page%2FcategorySection%2Fnati-melnychuk-49c-5-bNCRk-unsplash.jpg?alt=media&token=9201a361-8e46-4210-8ffa-42b28273704b"
                  alt=""
                  class="w-full h-full object-center object-cover"
                >
              </span>
              <span
                aria-hidden="true"
                class="
                  absolute
                  inset-x-0
                  bottom-0
                  h-2/3
                  bg-gradient-to-t
                  from-gray-800
                  opacity-50
                "
              />
              <span
                class="
                  relative
                  mt-auto
                  text-center text-xl
                  font-bold
                  text-white
                "
              >Perfume</span>
            </a>

            <a
              class="
                cursor-pointer
                relative
                w-56
                h-80
                rounded-lg
                p-6
                flex flex-col
                overflow-hidden
                hover:opacity-75
                xl:w-auto
              "
              @click="goToHipBags"
            >
              <span
                aria-hidden="true"
                class="absolute inset-0"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/fir-cart-c6633.appspot.com/o/home-page%2FcategorySection%2Femil-kalibradov-uLGQOgTwTdI-unsplash.jpg?alt=media&token=a40c326b-cb5b-47d7-b52d-6486a99122d3"
                  alt=""
                  class="w-full h-full object-center object-cover"
                >
              </span>
              <span
                aria-hidden="true"
                class="
                  absolute
                  inset-x-0
                  bottom-0
                  h-2/3
                  bg-gradient-to-t
                  from-gray-800
                  opacity-50
                "
              />
              <span
                class="
                  relative
                  mt-auto
                  text-center text-xl
                  font-bold
                  text-white
                "
              >Wallets</span>
            </a>

            <a
              class="
                cursor-pointer
                relative
                w-56
                h-80
                rounded-lg
                p-6
                flex flex-col
                overflow-hidden
                hover:opacity-75
                xl:w-auto
              "
              @click="goToLaptopSleeves"
            >
              <span
                aria-hidden="true"
                class="absolute inset-0"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/fir-cart-c6633.appspot.com/o/home-page%2FcategorySection%2Fannegret-kammer-uuBwaKIc9Kg-unsplash.jpg?alt=media&token=ce25d513-5cb3-4f03-9174-fd5d91025cf5"
                  alt=""
                  class="w-full h-full object-center object-cover"
                >
              </span>
              <span
                aria-hidden="true"
                class="
                  absolute
                  inset-x-0
                  bottom-0
                  h-2/3
                  bg-gradient-to-t
                  from-gray-800
                  opacity-50
                "
              />
              <span
                class="
                  relative
                  mt-auto
                  text-center text-xl
                  font-bold
                  text-white
                "
              >Watches</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 
    <div class="mt-6 px-4 sm:hidden">
      <a
        href="#"
        class="
          block
          text-sm
          font-semibold
          text-indigo-600
          hover:text-indigo-500
        "
        >Browse all categories<span aria-hidden="true"> &rarr;</span></a
      >
    </div> -->
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CategorySection",
  methods: {
    goToTotes() {
      this.$router.push({ name: "Categories" });
      this.$store.dispatch("setShowTotes");
    },
    goToBackpacks() {
      this.$router.push({ name: "Categories" });
      this.$store.dispatch("setShowBackpacks");
    },
    goToTravelBags() {
      this.$router.push({ name: "Categories" });
      this.$store.dispatch("setShowTravelBags");
    },
    goToHipBags() {
      this.$router.push({ name: "Categories" });
      this.$store.dispatch("setShowHipBags");
    },
    goToLaptopSleeves() {
      this.$router.push({ name: "Categories" });
      this.$store.dispatch("setShowLaptopSleeves");
    },
  },
  computed: {
    ...mapActions([
      "setShowAllProducts",
      "setShowTotes",
      "setShowBackpacks",
      "setShowTravelBags",
      "setShowHipBags",
      "setShowLaptopSleeves",
    ]),
  },
};
</script>

<style></style>

<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-32">
    <div
      class="
            relative
            z-0
            flex
            items-baseline
            justify-between
            pt-24
            pb-6
            pl-8
            border-b border-gray-200
          "
    >
      <h1 class="text-4xl font-extrabold tracking-tight text-gray-900">
        About Us
      </h1>
      <div />
    </div>
  </div>
</template>

<script>
export default {
name:'TheAboutHero'
}
</script>

<style>

</style>
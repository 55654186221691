<template>
  <div v-if="currentCat">
    <transition>
      <TheMobileFilterMenu v-if="mobileFilterMenu" />
    </transition>
    <div>
      <main class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div
          class="
            relative
            z-0
            flex
            items-baseline
            justify-between
            pt-24
            pb-6
            border-b border-gray-200
          "
        >
          <h1 class="text-4xl font-extrabold tracking-tight text-gray-900">
            {{ currentCategory }}
          </h1>
          <div>
            <button
              class="
                block
                lg:hidden
                text-sm
                font-medium
                text-gray-700
                hover:text-gray-900
              "
              @click="toggleMobileFilterMenu"
            >
              Filters
            </button>
          </div>
          <!-- the sort and order by -->
          <!-- <TheSortAndOrderBy /> -->
        </div>

        <section
          aria-labelledby="products-heading"
          class="pt-6 pb-24"
        >
          <h2
            id="products-heading"
            class="sr-only"
          >
            Products
          </h2>

          <div class="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
            <!-- Filters -->
            <form class="hidden lg:block">
              <h3 class="sr-only">
                Categories
              </h3>
              <ul
                role="list"
                class="
                  text-sm
                  font-medium
                  text-gray-900
                  space-y-4
                  pb-6
                  border-b border-gray-200
                "
              >
                <li
                  v-for="item in categoryItems"
                  :key="item.text"
                  class="cursor-pointer"
                  @click="item.atClick"
                >
                  <a>{{ item.text }}</a>
                </li>
              </ul>
              <!-- the color filter -->
              <!-- <TheColorFilter /> -->
              <!-- the category filter -->
              <!-- <TheCategoryFilter /> -->
              <!-- the size filter -->
              <!-- <TheSizeFilter /> -->
            </form>
            <!-- {{ currentCat }} -->
            <ProductForGrid :product-category="currentCat" />
            <!-- <ProductForGrid
              v-if="showAllProducts"
              :productCategory="products"
            />
            <ProductForGrid v-if="showBackpacks" :productCategory="backpacks" />
            <ProductForGrid v-if="showTotes" :productCategory="totes" />
            <ProductForGrid
              v-if="showTravelBags"
              :productCategory="travelBags"
            />
            <ProductForGrid v-if="showHipBags" :productCategory="hipBags" />
            <ProductForGrid
              v-if="showLaptopSleeves"
              :productCategory="laptopSleeves"
            /> -->
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import ProductForGrid from "@/components/Categories/ProductForGrid";
import TheMobileFilterMenu from "@/components/Categories/TheMobileFilterMenu";
//import TheSizeFilter from "@/components/Categories/TheSizeFilter";
//import TheCategoryFilter from "@/components/Categories/TheCategoryFilter";
//import TheColorFilter from "@/components/Categories/TheColorFilter";
//import TheSortAndOrderBy from "@/components/Categories/TheSortAndOrderBy";
//import TheMobileColorCategorySizeFilter from "@/components/Categories/TheMobileColorCategorySizeFilter";
export default {
  components: {
    ProductForGrid,
    // TheSizeFilter,
    //TheCategoryFilter,
    //TheColorFilter,
    //TheSortAndOrderBy,
    //TheMobileColorCategorySizeFilter,
    TheMobileFilterMenu,
  },
  data() {
    return {
      //  currentCategory: "All Products",
      categoryItems: [
        { text: "All Products", atClick: this.setShowAllProducts }, 
        { text: "Totes", atClick: this.setShowTotes },
        { text: "Backpacks", atClick: this.setShowBackpacks },
        { text: "Travel Bags", atClick: this.setShowTravelBags },
        { text: "Hip Bags", atClick: this.setShowHipBags },
        // { text: "Laptop Sleeves", atClick: this.setShowLaptopSleeves },
                    { text: "Belts", atClick: this.setShowBelts, available:true },
            { text: "Socks", atClick: this.setShowSocks, available:true },
            { text: "Footies", atClick: this.setShowFooties, available:true },
                        { text: "Perfume", atClick: this.setShowPerfume, available:true },
            { text: "Watches", atClick: this.setShowWatches, available:true },
                        { text: "Masks", atClick: this.setShowMasks, available:true },
            { text: "Soap & Shampoo", atClick: this.setShowSoapShampoo, available:true },
      ],
    };
  },
  methods: {
    ...mapMutations(["toggleMobileFilterMenu"]),
    ...mapActions([
      "setShowAllProducts",
      "setShowTotes",
      "setShowBackpacks",
      "setShowTravelBags",
      "setShowHipBags",
      "setShowLaptopSleeves",
      "setShowBelts",
      "setShowSocks",
      "setShowFooties",
      "setShowPerfume",
      "setShowWatches",
      "setShowMasks",
      "setShowSoapShampoo"
    ]),
  },
  computed: {
    ...mapState([
      "currentCat",
      "mobileFilterMenu",
      "products",
      // "showAllProducts",
      // "showTotes",
      // "showBackpacks",
      // "showTravelBags",
      // "showHipBags",
      // "showLaptopSleeves",
    ]),
    ...mapGetters([
      "totes",
      "backpacks",
      "travelBags",
      "hipBags",
      "laptopSleeves",
    ]),
    currentCategory: {
      get() {
        return this.$store.state.currentCategory;
      },
      set() {},
    },
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  @apply transition;
  @apply ease-in-out;
  @apply duration-300;
  @apply transform;
}
.v-enter,
.v-leave-to {
  @apply translate-x-full;
}
.v-leave,
.v-enter-to {
  @apply translate-x-0;
}
</style>
<!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "translate-x-full"
      -->

<template>
  <div>
    <h1 class="text-center">
      Dashboard
    </h1>
    <div class="ml-20 mt-80 flex gap-x-4">
      <button @click="logout">
        logout
      </button>
      <router-link to="/admin/products">
        Products
      </router-link> 
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
export default {
methods:{
      logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("loged out");
        })
        .catch((error) => {
          console.log(error);
        });
    },
}
}
</script>

<style>

</style>
<template>
  <div :class="open ? 'pro open' : 'pro'">
    <div class="question grid grid-cols-12">
      <p
        class="
          col-span-4
          px-6
          py-4
          whitespace-nowrap
          overflow-x-auto
          text-sm
          font-medium
          text-gray-900
        "
      >
        {{ pro.name }}
      </p>
      <p class="col-span-2 px-6 py-4 text-sm text-gray-500">
        {{ pro.category }}
      </p>
      <p class="col-span-2 px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {{ pro.brand }}
      </p>
      <p class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {{ pro.price }}
      </p>
      <div class="flex items-center justify-center">
        <img
          :src="pro.images[0]"
          alt=""
          class="w-4/5 h-4/5 lg:w-1/2 lg:h-1/2"
        >
      </div>
      <div class="flex items-center justify-center">
        <button
          class="text-gray-500"
          @mouseover="logInfo(pro)"
          @click="$emit('toggle-open', index)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M15.707 4.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0zm0 6a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L10 14.586l4.293-4.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div class="flex justify-center items-center text-gray-500">
        <a
          v-if="pro.productLink"
          :href="pro.productLink"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
            />
            <path
              d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
            />
          </svg>
        </a>
      </div>
    </div>
    <div class="answer border-r border-l shadow-2xl border-gray-200">
      <form class="space-y-8 divide-y divide-gray-200 px-4 overflow-y-scroll">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div class="flex justify-between">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Edit Product Info
              </h3>
              <div>
                <div class="pt-5">
                  <div class="flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('toggle-open', index)"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="
                        ml-3
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-indigo-600
                        hover:bg-indigo-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click.prevent="editProduct(tempInfo)"
                    >
                      Edit
                    </button>
                    <button
                      type="submit"
                      class="
                        ml-3
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-red-500
                      "
                      @click.prevent="deleteProduct(tempInfo)"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-6 sm:space-y-12">
              <div
                class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-4
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:pt-5
                "
              >
                <h3
                  class="
                    col-span-3
                    my-5
                    text-lg
                    leading-6
                    font-medium
                    text-gray-900
                  "
                >
                  Name - Brand - Category
                </h3>
                <div
                  class="
                    relative
                    border border-gray-300
                    rounded-md
                    px-3
                    py-2
                    shadow-sm
                    focus-within:ring-1
                    focus-within:ring-indigo-600
                    focus-within:border-indigo-600
                  "
                >
                  <label
                    for="name"
                    class="
                      absolute
                      -top-2
                      left-2
                      -mt-px
                      inline-block
                      px-1
                      bg-white
                      text-xs
                      font-medium
                      text-gray-900
                    "
                  >Name</label>
                  <input
                    id="name"
                    v-model="tempInfo.name"
                    type="text"
                    name="name"
                    class="
                      block
                      w-full
                      border-0
                      p-0
                      text-gray-900
                      placeholder-gray-500
                      focus:ring-0
                      sm:text-sm
                    "
                  >
                </div>
                <div
                  class="
                    relative
                    border border-gray-300
                    rounded-md
                    px-3
                    py-2
                    shadow-sm
                    focus-within:ring-1
                    focus-within:ring-indigo-600
                    focus-within:border-indigo-600
                  "
                >
                  <label
                    for="name"
                    class="
                      absolute
                      -top-2
                      left-2
                      -mt-px
                      inline-block
                      px-1
                      bg-white
                      text-xs
                      font-medium
                      text-gray-900
                    "
                  >Brand</label>
                  <input
                    id="name"
                    v-model="tempInfo.brand"
                    type="text"
                    name="name"
                    class="
                      block
                      w-full
                      border-0
                      p-0
                      text-gray-900
                      placeholder-gray-500
                      focus:ring-0
                      sm:text-sm
                    "
                  >
                </div>
                <div
                  class="
                    relative
                    border border-gray-300
                    rounded-md
                    px-3
                    py-2
                    shadow-sm
                    focus-within:ring-1
                    focus-within:ring-indigo-600
                    focus-within:border-indigo-600
                  "
                >
                  <label
                    for="name"
                    class="
                      absolute
                      -top-2
                      left-2
                      -mt-px
                      inline-block
                      px-1
                      bg-white
                      text-xs
                      font-medium
                      text-gray-900
                    "
                  >Category</label>
                  <input
                    id="name"
                    v-model="tempInfo.category"
                    type="text"
                    name="name"
                    class="
                      block
                      w-full
                      border-0
                      p-0
                      text-gray-900
                      placeholder-gray-500
                      focus:ring-0
                      sm:text-sm
                    "
                  >
                </div>
              </div>
              <div
                class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-4
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:pt-5
                "
              >
                <h3
                  class="
                    col-span-3
                    my-5
                    text-lg
                    leading-6
                    font-medium
                    text-gray-900
                  "
                >
                  Description - Oficial Price - Price
                </h3>
                <div
                  class="
                    relative
                    border border-gray-300
                    rounded-md
                    px-3
                    py-2
                    shadow-sm
                    focus-within:ring-1
                    focus-within:ring-indigo-600
                    focus-within:border-indigo-600
                  "
                >
                  <label
                    for="name"
                    class="
                      absolute
                      -top-2
                      left-2
                      -mt-px
                      inline-block
                      px-1
                      bg-white
                      text-xs
                      font-medium
                      text-gray-900
                    "
                  >Description</label>
                  <textarea
                    id="name"
                    v-model="tempInfo.description"
                    type="text"
                    name="name"
                    class="
                      block
                      w-full
                      border-0
                      p-0
                      text-gray-900
                      placeholder-gray-500
                      focus:ring-0
                      sm:text-sm
                    "
                  />
                </div>
                <div
                  class="
                    relative
                    border border-gray-300
                    rounded-md
                    px-3
                    py-2
                    shadow-sm
                    focus-within:ring-1
                    focus-within:ring-indigo-600
                    focus-within:border-indigo-600
                  "
                >
                  <label
                    for="name"
                    class="
                      absolute
                      -top-2
                      left-2
                      -mt-px
                      inline-block
                      px-1
                      bg-white
                      text-xs
                      font-medium
                      text-gray-900
                    "
                  >Oficial Price</label>
                  <input
                    id="name"
                    v-model="tempInfo.oficialPrice"
                    type="text"
                    name="name"
                    class="
                      block
                      w-full
                      border-0
                      p-0
                      text-gray-900
                      placeholder-gray-500
                      focus:ring-0
                      sm:text-sm
                    "
                  >
                </div>
                <div
                  class="
                    relative
                    border border-gray-300
                    rounded-md
                    px-3
                    py-2
                    shadow-sm
                    focus-within:ring-1
                    focus-within:ring-indigo-600
                    focus-within:border-indigo-600
                  "
                >
                  <label
                    for="name"
                    class="
                      absolute
                      -top-2
                      left-2
                      -mt-px
                      inline-block
                      px-1
                      bg-white
                      text-xs
                      font-medium
                      text-gray-900
                    "
                  >Price</label>
                  <input
                    id="name"
                    v-model="tempInfo.price"
                    type="text"
                    name="name"
                    class="
                      block
                      w-full
                      border-0
                      p-0
                      text-gray-900
                      placeholder-gray-500
                      focus:ring-0
                      sm:text-sm
                    "
                  >
                </div>
              </div>
              <div
                class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-x-4
                  sm:gap-y-8
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:pt-5
                "
              >
                <h3
                  class="
                    col-span-3
                    my-5
                    text-lg
                    leading-6
                    font-medium
                    text-gray-900
                  "
                >
                  Features
                </h3>
                <div
                  v-for="(fe, i) in tempInfo.features"
                  :key="i"
                >
                  <div
                    class="
                      relative
                      border border-gray-300
                      rounded-md
                      px-3
                      py-2
                      shadow-sm
                      focus-within:ring-1
                      focus-within:ring-indigo-600
                      focus-within:border-indigo-600
                    "
                  >
                    <label
                      for="name"
                      class="
                        absolute
                        -top-2
                        left-2
                        -mt-px
                        inline-block
                        px-1
                        bg-white
                        text-xs
                        font-medium
                        text-gray-900
                      "
                    >Feature {{ i + 1 }}</label>
                    <input
                      v-model="tempInfo.features[i]"
                      type="text"
                      class="
                        block
                        w-full
                        border-0
                        p-0
                        text-gray-900
                        placeholder-gray-500
                        focus:ring-0
                        sm:text-sm
                      "
                    >
                  </div>
                </div>
              </div>

              <div
                class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-4
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:pt-5
                "
              >
                <h3
                  class="
                    col-span-3
                    my-5
                    text-lg
                    leading-6
                    font-medium
                    text-gray-900
                  "
                >
                  Images
                </h3>
                <div
                  v-for="(fe, img) in tempInfo.images"
                  :key="img"
                >
                  <div
                    class="
                      relative
                      border border-gray-300
                      rounded-md
                      px-3
                      py-2
                      shadow-sm
                      focus-within:ring-1
                      focus-within:ring-indigo-600
                      focus-within:border-indigo-600
                    "
                  >
                    <label
                      for="name"
                      class="
                        absolute
                        -top-2
                        left-2
                        -mt-px
                        inline-block
                        px-1
                        bg-white
                        text-xs
                        font-medium
                        text-gray-900
                      "
                    >Image {{ img + 1 }}</label>
                    <input
                      v-model="tempInfo.images[img]"
                      type="text"
                      class="
                        block
                        w-full
                        border-0
                        p-0
                        text-gray-900
                        placeholder-gray-500
                        focus:ring-0
                        sm:text-sm
                      "
                    >
                  </div>
                </div>
              </div>
              <div
                class="
                  sm:grid
                  sm:grid-cols-3
                  sm:gap-4
                  sm:items-start
                  sm:border-t
                  sm:border-gray-200
                  sm:py-5
                "
              >
                <label
                  for="product-link"
                  class="
                    block
                    text-sm
                    font-medium
                    text-gray-700
                    sm:mt-px sm:pt-2
                  "
                >
                  Product Link
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="product-link"
                    v-model="tempInfo.productLink"
                    type="text"
                    name="product-link"
                    autocomplete="product-link"
                    class="
                      max-w-lg
                      block
                      w-full
                      shadow-sm
                      focus:ring-indigo-500 focus:border-indigo-500
                      sm:max-w-xs sm:text-sm
                      border-gray-300
                      rounded-md
                    "
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
export default {
  name: "Accordion2",
  props: {
    pro: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    open: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["toggle-open"],
  data() {
    return {
      tempInfo: [],
    };
  },
  methods: {
    deleteProduct(pro) {
      const db = firebase.firestore();
      db.collection("tailProducts")
        .doc(pro.id)
        .delete()
        .then((res) => {
          console.log(res);
        });
    },
    logInfo(pro) {
      this.tempInfo = pro;
    },
    editProduct(product) {
      console.log(product);
      const db = firebase.firestore();
      db.collection("tailProducts")
        .doc(product.id)
        .update({
          name: product.name,
          brand: product.brand,
          category: product.category,
          description: product.description,
          oficialPrice: product.oficialPrice,
          price: product.price,
          features: product.features,
          images: product.images,
          productLink: product.productLink,
        })
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>
<template>
  <!--
this template requires @tailwindcss/aspect-ratio
-->
  <div
    class="fixed z-50 inset-0 overflow-y-auto"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex min-h-screen text-center md:block md:px-2 lg:px-4"
      style="font-size: 0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"

        injected in app.vue
                <div
          class="
            backdrop-blur backdrop-filter
            hidden
            fixed
            inset-0
            bg-gray-900 bg-opacity-20
            
            md:block
          "
          aria-hidden="true"
          @click="toggleQuickview"
        ></div>
    -->

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden md:inline-block md:align-middle md:h-screen"
        aria-hidden="true"
      >&#8203;</span>

      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
        To: "opacity-100 translate-y-0 md:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 md:scale-100"
        To: "opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
    -->
  
      <div
        v-click-outside="toggleQuickview"
        class="
          flex
          text-base text-left
          transform
          transition
          w-full
          md:inline-block
          md:max-w-2xl
          md:px-4
          md:my-8
          md:align-middle
          lg:max-w-4xl
        "
      >
        <div
          class="
            w-full
            relative
            flex
            items-center
            bg-white
            px-4
            pt-14
            pb-8
            overflow-hidden
            shadow-2xl
            sm:px-6
            sm:pt-8
            md:p-6
            lg:p-8
          "
        >
          <button
            type="button"
            class="
              absolute
              top-4
              right-4
              text-gray-400
              hover:text-gray-500
              sm:top-8
              sm:right-6
              md:top-6
              md:right-6
              lg:top-8
              lg:right-8
            "
          >
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <button
              @click="toggleQuickview"
            >
              <IconBase
                view-box="0 0 24 24"
                class="h-6 w-6"
                @click="toggleQuickview"
              >
                <IconOutlineX />
              </IconBase>
            </button>
          </button>

          <div
            class="
              w-full
              grid grid-cols-1
              gap-y-8 gap-x-6
              items-start
              sm:grid-cols-12
              lg:gap-x-8
            "
          >
            <div class="sm:col-span-4 lg:col-span-5">
              <div
                class="
                  aspect-w-1 aspect-h-1
                  rounded-lg
                  bg-gray-100
                  overflow-hidden
                "
              >
                <div class="flex items-center justify-center bg-white">
                  <div>
                    <img
                      :src="currentQuickview.images[0]"
                      alt=""
                      class="w-3/4 h-3/4"
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="sm:col-span-8 lg:col-span-7">
              <h2 class="text-2xl font-extrabold text-gray-900 sm:pr-12">
                {{ currentQuickview.name }}
              </h2>

              <section
                aria-labelledby="information-heading"
                class="mt-3"
              >
                <h3
                  id="information-heading"
                  class="sr-only"
                >
                  Product information
                </h3>
                <div>
                  <!-- <div v-for="pro in currentQuickview" :key="pro.name"> -->
                  <p class="text-2xl text-gray-900">
                    <span>$</span>{{ currentQuickview.price }}
                  </p>
                </div>

                <div class="mt-6">
                  <h4 class="sr-only">
                    Description
                  </h4>

                  <p class="text-sm text-gray-700">
                    {{ currentQuickview.description }}
                  </p>
                </div>
                <div class="mt-6">
                  <h4 class="sr-only">
                    Features
                  </h4>

                  <p
                    v-for="feature in currentQuickview.features"
                    :key="feature"
                    class="text-xs py-0.5 text-gray-700"
                  >
                    {{ feature }}
                  </p>
                </div>  
              </section>

              <section
                aria-labelledby="options-heading"
                class="mt-6"
              >
                <h3
                  id="options-heading"
                  class="sr-only"
                >
                  Product options
                </h3>
                <form>
                  <div class="mt-6 flex justify-between items-center">
                    <div
                      v-if="currentItem"
                      class="flex gap-x-8 items-center bg-indigo-500 rounded-full p-1"
                    >
                      <button
                        v-if="currentItem"
                        type="button"
                        class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        @click.prevent="decreaseQuantity"
                      >
                        <IconBase
                          class="h-5 w-5"
                          view-box="0 0 20 20"
                          fill="currentColor"
                        >
                          <IconSolidMinusSm />
                        </IconBase>
                      </button>
                      <p class="text-white">
                        {{ currentItem }}
                      </p>
                      <button
                        v-if="currentItem"
                        type="button"
                        class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        @click.prevent="increaseQuantity"
                      >
                        <IconBase
                          view-box="0 0 20 20"      
                          class="h-5 w-5"
                          fill="currentColor"
                        >
                          <IconSolidPlusSm />
                        </IconBase>
                      </button>
                    </div>
                    <div>
                      <button
                        v-if="currentItem"
                        class=" border-b border-gray-500"
                        @click.prevent="removeItemFromCart"
                      >
                        remove
                      </button>
                    </div>
                    <button
                      v-if="!currentItem"
                      class="
                        w-full
                        bg-indigo-600
                        border border-transparent
                        rounded-md
                        py-3
                        px-8
                        flex
                        items-center
                        justify-center
                        text-base
                        font-medium
                        text-white
                        hover:bg-indigo-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-offset-gray-50
                        focus:ring-indigo-500
                      "
                      @click.prevent="addToCart(currentQuickview)"
                    >
                      Add to bag
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xpopup from '@/assets/sound/xpopup.wav'
import IconBase from '@/components/Icons/IconBase'
import IconOutlineX from '@/components/Icons/IconOutlineX'
import IconSolidMinusSm from '@/components/Icons/IconSolidMinusSm'
import IconSolidPlusSm from '@/components/Icons/IconSolidPlusSm'
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "TheProductQuickview",
  components:{
IconBase,
IconOutlineX,
IconSolidMinusSm,
IconSolidPlusSm
  },
    computed: {
    ...mapState(["currentQuickview", "cart", "showQuickview"]),
    currentItem() {
      const item = this.cart.find(
        (item) => item.id === this.currentQuickview.id
      );
      return item?.quantity;
    },
  },
  methods: {
    toggleQuickview(){
      this.$store.dispatch('toggleQuickview');
               let snd = new Audio(xpopup)
      snd.play() 
    },
    ...mapMutations(["increaseQuantity", "removeItemFromCart"]),
    ...mapActions(["addToCart", "decreaseQuantity"]),
  },

};
</script>
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import vClickOutside from "v-click-outside";
import VueTippy from "vue-tippy";
import { currency } from "@/currency";

Vue.use(VueTippy)
Vue.use(vClickOutside);

Vue.filter("currency", currency);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

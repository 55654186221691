<template>
  <!-- Mobile menu and search (lg-) -->
  <div class="flex-1 flex items-center lg:hidden">
    <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
    <button
      type="button"
      class="-ml-2 p-2 text-white"
      @click="toggleMobileMenu"
    >
      <span class="sr-only">Open menu</span>
      <!-- Heroicon name: outline/menu -->
      <IconBase
        class="h-6 w-6"
        view-box="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        fill="none"
      >
        <IconOutlineMenu />
      </IconBase>
    </button>

    <!-- Search -->
    <!-- <a
      href="#"
      class="ml-2 p-2 text-white"
    >
      <span class="sr-only">Search</span>
      <IconBase
        class="w-6 h-6"
        view-box="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
        fill="none"
      ><IconMobileSearch /></IconBase>
    </a> -->
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import IconBase from '@/components/Icons/IconBase'
//import IconMobileSearch from '@/components/Icons/IconMobileSearch'
import IconOutlineMenu from '@/components/Icons/IconOutlineMenu'
export default {
name:'TheMobileMenuAndSearch',
components:{
    IconBase,
    //IconMobileSearch,
    IconOutlineMenu
},
methods:{
    ...mapMutations([
        "toggleMobileMenu"
    ])
}
}
</script>

<style>

</style>
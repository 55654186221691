<template>
  <div class="flex">
    <!--
      'Women' mega menu, show/hide based on flyout menu state.

      Entering: "transition ease-out duration-200"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition ease-in duration-150"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="absolute top-full inset-x-0 text-gray-500 sm:text-sm">
      <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
      <div
        class="absolute inset-0 top-1/2 bg-white shadow"
        aria-hidden="true"
      />

      <div class="relative bg-white z-10">
        <div class="max-w-7xl mx-auto px-8">
          <div class="grid grid-cols-4 gap-x-8 pt-10 pb-12">
            <div
              v-for="menu in allMenus" 
              :key="menu.name"
            >
              <p class="font-medium text-gray-900">
                {{ menu.name }}
              </p>
              <div
                v-for="(item, index) in menu.currentMenu" 
                :key="index"
              >
                <ul
                  role="list" 
                  class="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                >
                  <li v-if="!item.available">
                    <p>
                      <span
                        v-tippy
                        content="Coming Soon"
                        class="cursor-not-allowed"
                      >
                        {{ item.text }}
                      </span>
                    </p>
                  </li>
                  <li @click="item.atClick">
                    <router-link
                      v-if="item.available"
                      :to="`/categories/${item.text}`"
                    >
                      {{ item.text }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TheMenFlyoutMenu",
  data() {
    return {
      allMenus: [
        {
          name: "Bags",
          currentMenu: [
            {
              text: "Messanger Bags",
              atClick: this.setShowMensMessenger, available:true
            
            },
            {
              text: "Laptop Bags",
              atClick: this.setShowMensLaptop,
              available:true
           
            },
            { text: "Backpacks", atClick: this.setShowBackpacks },
            {
              text: "Travel Bags",
              atClick: this.setShowTravelBags,
            },
          ],
        },
        {
          name: "Clothing",
          currentMenu: [
            { text: "Belts", atClick: this.setShowBelts },
            { text: "Socks", atClick: this.setShowSocks },
            { text: "T Shirts", atClick: this.setShowFooties },
          ],
        },
        {
          name: "Accessories",
          currentMenu: [
            { text: "Hats", atClick: this.setShowPerfume },
            { text: "Watches", atClick: this.setShowWatches },
            { text: "Wallets", atClick: this.setShowWatches },
          ],
        },
        {
          name: "Others",
          currentMenu: [
            { text: "Deodorant", atClick: this.setShowMasks },
            { text: "Shaving Lotion", atClick: this.setShowSoapShampoo },
            { text: "Shaving Blades", atClick: this.setShowGifts },
            { text: "Cologne", atClick: this.setShowSigOther },
          ],
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "setShowMensLaptop",
      "setShowMensMessenger",
      "setShowTotes",
      "setShowBackpacks",
      "setShowTravelBags",
      "setShowHipBags",
      "setShowBelts",
      "setShowSocks",
      "setShowFooties",
      "setShowPerfume",
      "setShowWatches",
      "setShowMasks",
      "setShowSoapShampoo",
      "setShowGifts",
      "setShowSigOther",
      "setShowMBackpacks",
    ]),
  },
};
</script>

<style></style>

<template>
  <div
    class="
      grid grid-cols-1
      gap-y-10 gap-x-6
      sm:grid-cols-2
      lg:col-span-3 lg:gap-x-8
    "
  >
    <div
      v-for="product in productCategory"
      :key="product.name"
    >
      <!-- <router-link

        :to="{ name: 'Product', params: { name: product.name } }"
        class="group text-sm"
      > -->
      <router-link
        to="#"
        class="group text-sm"
      >
        <!-- <a href="/product" class="group text-sm"> -->
        <div
          class="
            w-full
            aspect-w-1 aspect-h-1
            rounded-lg
            overflow-hidden
            bg-gray-900
            group-hover:opacity-75 group-hover:shadow-2xl
            shadow-md
            transition
            duration-300
            z-0
          "
          @mouseenter="setQuickviewPoroduct(product)"
        >
          <div
            class="
              flex
              justify-center
              items-center
              transition
              duration-750
              ease-in-out
              opacity-0
              group-hover:bg-opacity-90
              group-hover:opacity-100
              p-4
              bg-gray-100
              text-white
              z-30
            "
          >
            <button 
              type="button"
              class="
            mt-8
            inline-block
            bg-gray-900
            border border-transparent
            rounded-md
            py-3
            px-8
            text-base
            font-medium
            text-white
           
            hover:group-bg-opacity-0
            hover:border
            hover:text-gray-900
            hover:bg-gray-100
            hover:border-gray-900
            transition
            duration-300
            
          "
              @click="toggleQuickview"
            >
              Preview
            </button>
            <!-- <button
              type="button"
              class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="toggleQuickview"
            >
              Button text
            </button> -->
            <!-- <button
              class=" p-4 bg-white border text-black  border-black  "
              @click="toggleQuickview"
            >
              hhh
            </button> -->
          </div>
          <div class="flex justify-center items-center bg-white">
            <img
              :src="product.images[0]"
              alt="White fabric pouch with white zipper, black zipper pull, and black elastic loop."
              class="w-3/4 h-3/4"
            >
          </div>
        </div>
        <!-- <transition>
          <div v-if="showQuickview">
            <TheProductQuickview />
          </div>
        </transition> -->
        <h3 class="mt-4 font-medium text-gray-900">
          {{ product.name }}
        </h3>
        <p class="text-gray-500 italic">
          {{ product.brand }}
        </p>
        <p class="mt-2 font-medium text-gray-900">
          <span>$</span>{{ product.price }}
        </p>
        <!-- </a> -->
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import popup from '@/assets/sound/popup.mp3'
// import TheProductQuickview from "@/components/Products/TheProductQuickview";
export default {
  name: "ProductForGrid",
  // components: {
  //   TheProductQuickview,
  // },
  props: {
    productCategory: {
      type: Array,
      required: true,
    },
  },
    computed: {
    ...mapState(["showQuickview", "currentQuickview"]),
  },
  methods: {
    setQuickviewPoroduct(product) {
      this.$store.commit("setQuickviewProduct", product);
      // console.log(p);
      // console.log("ssetting..");
    },
    toggleQuickview(){
         let snd = new Audio(popup)
      snd.play() 
      this.$store.dispatch('toggleQuickview')
    }
  //  ...mapActions(["toggleQuickview"]),
  },
};
</script>

<style scoped>
.v-enter-active{
  @apply ease-out;
  @apply duration-300;
}
.v-enter, .v-leave-to {
  @apply opacity-0;
  @apply translate-y-4;
  @apply md:translate-y-0;
  @apply md:scale-95;
}
.v-enter-to, .v-leave {
  @apply opacity-100;
  @apply translate-y-0;
  @apply md:scale-100;
}
.v-leave-active {
  @apply ease-in;
  @apply duration-200;
}
</style>

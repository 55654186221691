<template>
  <section
    aria-labelledby="social-impact-heading"
    class="max-w-7xl mx-auto pt-24 px-4 sm:pt-32 sm:px-6 lg:px-8"
  >
    <div class="relative rounded-lg overflow-hidden">
      <div class="absolute inset-0">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/fir-cart-c6633.appspot.com/o/home-page%2Fgeorgi-dyulgerov-6CO4yjKCCsA-unsplash.jpg?alt=media&token=7507af81-99f6-48d5-8153-d0c690125996"
          alt=""
          class="w-full h-full object-center object-cover"
        >
      </div>
      <div
        class="
          relative
          bg-gray-900 bg-opacity-75
          py-32
          px-6
          sm:py-40 sm:px-12
          lg:px-16
        "
      >
        <div
          class="
            relative
            max-w-3xl
            mx-auto
            flex flex-col
            items-center
            text-center
          "
        >
          <h2
            id="social-impact-heading"
            class="
              text-3xl
              font-extrabold
              tracking-tight
              text-white
              sm:text-4xl
            "
          >
            <span class="block sm:inline">Level up</span>
            <span class="block sm:inline ml-2">your style</span>
          </h2>
          <p class="mt-3 text-xl text-white">
            Upgrade your style to beauty and elegance. Post a picture to social
            media and watch it get more likes than life-changing announcements.
            Reflect on the shallow nature of existence. At least you have a
            really nice bag to go around.
          </p>
          <a
            class="
              mt-8
              w-full
              block
              bg-white
              border border-transparent
              rounded-md
              py-3
              px-8
              text-base
              font-medium
              text-gray-900
              hover:bg-gray-100
              sm:w-auto
            "
            @click="goToAllProducts"
          >View All Categories</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    goToAllProducts() {
      this.$router.push({ name: "Categories" });
      this.$store.dispatch("setShowAllProducts");
    },
  },
  computed: {
    ...mapActions(["setShowAllProducts"]),
  },
};
</script>

<style></style>

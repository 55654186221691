import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../Pages/Home/Home.vue";
import Categories from "../Pages/Categories/";
import Product from "../Pages/Products/index";
import SignInAndRegistration from "@/Pages/SignInAndRegistration";
import About from "@/Pages/About";
import Contact from "@/Pages/Contact";
import Terms from '@/Pages/Terms';
import Admin from '@/Pages/Admin'
import AdminDashboard from '@/Pages/Admin/Dashboard'
import AdminProducts from '@/Pages/Admin/Products'
import ProductsTable from '@/Pages/Admin/Products/ProductsTable'
import Privacy from '@/Pages/Privacy'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Categories/:name",
    name: "Categories",
    component: Categories,
  },
  {
    path: "/product/:name",
    name: "Product",
    component: Product,
  },
  {
    path: "/login",
    name: "SignInAndRegistration",
    component: SignInAndRegistration,
  },
  {
    path: "/About",
    name: "About",
    component: About,
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: '/Terms',
    component: Terms
  },
  {
    path: '/Admin',
    name: 'Admin',
    component:Admin
  },
  {
    path: '/Admin/Dashboard',
    name: 'AdminDashboard',
    component: AdminDashboard
  },
  {
    path: '/Admin/Products',
    mame: 'AdminProducts',
    component: AdminProducts
  },
  {
    path: '/Admin/ProductsTable',
    name: 'ProductsTable',
    component: ProductsTable
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: Privacy
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <!-- 'Men' tab panel, show/hide based on tab state. -->
  <div
    id="tabs-1-panel-2"
    class="px-4 pt-10 pb-6 space-y-12"
    aria-labelledby="tabs-1-tab-2"
    role="tabpanel"
    tabindex="0"
  >
    <div class="grid grid-cols-1 items-start gap-y-10 gap-x-6">
      <div class="grid grid-cols-1 gap-y-10 gap-x-6">
        <div
          v-for="menu in allMenus"
          :key="menu.name"
        >
          <p class="font-medium text-gray-900">
            {{ menu.name }}
          </p>
          <div
            v-for="(item, index) in menu.currentMenu"
            :key="index"
          >
            <ul class="mt-6 space-y-6">
              <li v-if="!item.available">
                <p class="text-gray-500">
                  <span
                    v-tippy
                    content="Coming Soon"
                    class="cursor-not-allowed"
                  >
                    {{ item.text }}
                  </span>
                </p>
              </li>
              <li @click="clickedMobileMenuItem(item)">
                <router-link
                  v-if="item.available"
                  class="text-gray-500"
                  :to="`/categories/${item.text}`"
                >
                  {{ item.text }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "TheMenMobileMenu",
  data() {
    return {
      allMenus: [
        {
          name: "Bags",
          currentMenu: [
            {
              text: "Messanger Bags",
              atClick: this.setShowMensMessenger,
              available:true
         
            },
            {
              text: "Laptop Bags",
              atClick: this.setShowMensLaptop,
              available:true
        
            },
            { text: "Backpacks", atClick: this.setShowBackpacks },
            {
              text: "Travel Bags",
              atClick: this.setShowTravelBags,
            },
          ],
        },
        {
          name: "Clothing",
          currentMenu: [
            { text: "Belts", atClick: this.setShowBelts },
            { text: "Socks", atClick: this.setShowSocks },
            { text: "T Shirts", atClick: this.setShowFooties },
          ],
        },
        {
          name: "Accessories",
          currentMenu: [
            { text: "Hats", atClick: this.setShowPerfume },
            { text: "Watches", atClick: this.setShowWatches },
            { text: "Wallets", atClick: this.setShowWatches },
          ],
        },
        {
          name: "Others",
          currentMenu: [
            { text: "Deodorant", atClick: this.setShowMasks },
            { text: "Shaving Lotion", atClick: this.setShowSoapShampoo },
            { text: "Shaving Blades", atClick: this.setShowGifts },
            { text: "Cologne", atClick: this.setShowSigOther },
          ],
        },
      ],
    };
  },
  methods: {
    clickedMobileMenuItem(item) {
      item.atClick();
      this.toggleMobileMenu();
    },
    ...mapActions([
      "setShowMensLaptop",
      "setShowMensMessenger",
      "setShowTotes",
      "setShowBackpacks",
      "setShowTravelBags",
      "setShowHipBags",
      "setShowBelts",
      "setShowSocks",
      "setShowFooties",
      "setShowPerfume",
      "setShowWatches",
      "setShowMasks",
      "setShowSoapShampoo",
      "setShowGifts",
      "setShowSigOther",
      "setShowMBackpacks",
    ]),
    ...mapMutations(["toggleMobileMenu"]),
  },
};
</script>

<template>
  <form class="space-y-8 divide-y divide-gray-200 px-8 pb-16 mx-8 shadow-2xl">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Add New Product
          </h3>
        </div>
        <div class="space-y-6 sm:space-y-5">
          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="name"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Name
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input
                id="name"
                v-model="name"
                type="text"
                name="name"
                autocomplete="given-name"
                class="
                  max-w-lg
                  block
                  w-full
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  sm:max-w-xs sm:text-sm
                  border-gray-300
                  rounded-md
                "
              >
            </div>
          </div>

          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="brand"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Brand
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input
                id="brand"
                v-model="brand"
                type="text"
                name="brand"
                autocomplete="brand"
                class="
                  max-w-lg
                  block
                  w-full
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  sm:max-w-xs sm:text-sm
                  border-gray-300
                  rounded-md
                "
              >
            </div>
          </div>

          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="category"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Category
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input
                id="category"
                v-model="category"
                type="text"
                name="category"
                autocomplete="category"
                class="
                  max-w-lg
                  block
                  w-full
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  sm:max-w-xs sm:text-sm
                  border-gray-300
                  rounded-md
                "
              >
            </div>
          </div>

          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="description"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Description
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                id="description"
                v-model="description"
                name="description"
                type="text"
                autocomplete="description"
                class="
                  block
                  sm:max-w-xs
                  max-w-lg
                  w-full
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              />
            </div>
          </div>
          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="oficial-price"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Oficial Price
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input
                id="oficial-price"
                v-model="oficialPrice"
                type="text"
                name="oficial-price"
                autocomplete="oficial-price"
                class="
                  block
                  max-w-lg
                  w-full
                  sm:max-w-xs
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              >
            </div>
          </div>

          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="Price"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Price
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input
                id="Price"
                v-model="price"
                type="text"
                name="Price"
                autocomplete="Price"
                class="
                  block
                  max-w-lg
                  w-full
                  sm:max-w-xs
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              >
            </div>
          </div>
          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <div>
              <label
                for="features"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Features
              </label>
              <div class="mt-7">
                <!-- <button @click.prevent="newFeature">
                Add Feature  
              </button> -->
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-transparent
                    text-sm
                    font-medium
                    rounded-md
                    shadow-sm
                    text-white
                    bg-indigo-600
                    hover:bg-indigo-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                  @click.prevent="newFeature"
                >
                  Add Feature
                </button>
              </div>
            </div>

            <div
              v-for="(fe, index) in emptyFe"
              :key="index"
            >
              <label
                for="features"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Feature {{ index + 1 }}
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2 flex">
                <input
                  id="features"
                  ref="feat"
                  v-model="features[index]"
                  type="text"
                  name="features"
                  autocomplete="features"
                  class="
                    block
                    max-w-lg
                    w-11/12
                    sm:max-w-xs
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                >
                <button
                  class="text-gray-500 flex items-center justify-center"
                  @click.prevent="removeFeature(index)"
                >
                  <IconBase
                    class="h-5 w-5 -mr-8"
                    view-box="0 0 20 20"
                    fill="currentColor"
                    stroke-width="0.5"
                  >
                    <IconRemoveEx />
                  </IconBase>
                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg> -->
                </button>
              </div>
            </div>
          </div>

          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <div>
              <label
                for="images"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Images
              </label>
              <!-- <button @click.prevent="newImage">
                +
              </button> -->
              <div class="mt-7">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    px-4
                    py-2
                    border border-transparent
                    text-sm
                    font-medium
                    rounded-md
                    shadow-sm
                    text-white
                    bg-indigo-600
                    hover:bg-indigo-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                  "
                  @click.prevent="newImage"
                >
                  Add Image
                </button>
              </div>
            </div>
            <div
              v-for="(im, index) in emptyIm"
              :key="index"
            >
              <label
                for="images"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Image {{ index + 1 }}
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2 flex">
                <input
                  id="images"
                  v-model="images[index]"
                  type="text"
                  name="images"
                  autocomplete="images"
                  class="
                    block
                    max-w-lg
                    w-11/12
                    sm:max-w-xs
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                >

                <button
                  class="text-gray-500 flex items-center justify-center"
                  @click.prevent="removeImage(index)"
                >
                  <IconBase
                    class="h-5 w-5 -mr-8"
                    view-box="0 0 20 20"
                    fill="currentColor"
                    stroke-width="0.5"
                  >
                    <IconRemoveEx />
                  </IconBase>
                </button>
              </div>
            </div>
          </div>
          <div
            v-for="(image, p) in images"
            :key="p"
          >
            <img
              :src="image"
              alt=""
            >
          </div>
          <div
            class="
              sm:grid
              sm:grid-cols-3
              sm:gap-4
              sm:items-start
              sm:border-t
              sm:border-gray-200
              sm:pt-5
            "
          >
            <label
              for="product-link"
              class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Product Link
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <input
                id="product-link"
                v-model="productLink"
                type="text"
                name="product-link"
                autocomplete="product-link"
                class="
                  max-w-lg
                  block
                  w-full
                  shadow-sm
                  focus:ring-indigo-500 focus:border-indigo-500
                  sm:max-w-xs sm:text-sm
                  border-gray-300
                  rounded-md
                "
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <button
          type="button"
          class="
            bg-white
            py-2
            px-4
            border border-gray-300
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-gray-700
            hover:bg-gray-50
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
        >
          Cancel
        </button>
        <button
          type="submit"
          class="
            ml-3
            inline-flex
            justify-center
            py-2
            px-4
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-indigo-600
            hover:bg-indigo-700
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-indigo-500
          "
          @click.prevent="addProduct"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import IconBase from "@/components/Icons/IconBase";
import IconRemoveEx from "@/components/Icons/IconRemoveEx";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
export default {
  name: "TheAddProForm",
  components: {
    IconBase,
    IconRemoveEx,
  },
  data() {
    return {
      name: "",
      brand: "",
      category: "",
      description: "",
      oficialPrice: "",
      price: "",
      features: [],
      images: [],
      productLink: "",
      emptyFe: [{}],
      emptyIm: [{}],
    };
  },
  methods: {
    addProduct() {
      const db = firebase.firestore();
      db.collection("tailProducts")
        .add({
          name: this.name,
          brand: this.brand,
          category: this.category,
          description: this.description,
          oficialPrice: this.oficialPrice,
          price: this.price,
          features: this.features,
          images: this.images,
          productLink: this.productLink,
        })
        .then((res) => {
          console.log(res.id);
        });
    },
    newFeature() {
      this.emptyFe.push({});
      console.log("ok");
    },
    removeFeature(index) {
      this.features.splice(index, 1);
      this.emptyFe.splice(index, 1);
    },
    newImage() {
      this.emptyIm.push({});
    },
    removeImage(index) {
      this.images.splice(index, 1);
      this.emptyIm.splice(index, 1);
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="text-center">
        <h2
          class="
            text-base
            font-semibold
            text-indigo-600
            tracking-wide
            uppercase
          "
        >
          All
        </h2>
        <p
          class="
            mt-1
            text-4xl
            font-extrabold
            text-gray-900
            sm:text-5xl sm:tracking-tight
            lg:text-6xl
          "
        >
          Products
        </p>
        <p class="max-w-xl mt-5 mx-auto text-xl text-gray-500">
          View, add, edit and delete.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
};
</script>

<style>
</style>
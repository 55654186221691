<template>
  <div>
    <TheSignIn v-if="signIn" />
    <TheRegistration v-if="registration" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TheSignIn from "@/Pages/SignInAndRegistration/TheSignIn";
import TheRegistration from "@/Pages/SignInAndRegistration/TheRegistration";
export default {
  name: "SignInAndRegistration",
  components: {
    TheSignIn,
    TheRegistration,
  },
  computed: {
    ...mapState(["signIn", "registration"]),
  },
};
</script>

<style></style>

<template>
  <!--
  This example requires require('@tailwindcss/forms')
-->
  <div
    v-if="openMobileMenu"
    class="bg-white"
  >
    <!--
    Mobile menu

    Off-canvas menu for mobile, show/hide based on off-canvas menu state.
  -->
    <div
      class="fixed inset-0 flex z-40 lg:hidden"
      role="dialog"
      aria-modal="true"
    >
      <!--
      Off-canvas menu overlay, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"

 <div
        class="fixed inset-0 bg-black bg-opacity-25"
        aria-hidden="true"
      />
    -->

      <!--
      Off-canvas menu, show/hide based on off-canvas menu state.

      Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
        To: "translate-x-0"
      Leaving: "transition ease-in-out duration-300 transform"
        From: "translate-x-0"
        To: "-translate-x-full"
    -->
      <div
        v-click-outside="toggleMobileMenu"
        class="
          relative
          max-w-xs
          w-full
          bg-white
          shadow-xl
          pb-12
          flex flex-col
          overflow-y-auto
        "
      >
        <div class="px-4 pt-5 pb-2 flex">
          <button
            type="button"
            class="
              -m-2
              p-2
              rounded-md
              inline-flex
              items-center
              justify-center
              text-gray-400
            "
            @click="toggleMobileMenu"
          >
            <span class="sr-only">Close menu</span>
            <!-- Heroicon name: outline/x -->
            <IconBase
              class="h-6 w-6"
              view-box="0 0 24 24"
              fill="none"
              aria-hidden="true"
            >
              <IconOutlineX />
            </IconBase>
          </button>
        </div>

        <!-- Links -->
        <div class="mt-2">
          <div class="border-b border-gray-200">
            <div
              class="-mb-px flex px-4 space-x-8"
              aria-orientation="horizontal"
              role="tablist"
            >
              <!-- Selected: "text-indigo-600 border-indigo-600", Not Selected: "text-gray-900 border-transparent" -->
              <button
                id="tabs-1-tab-1"
                :class="
                  openWomen
                    ? 'text-indigo-600 border-indigo-600'
                    : 'text-gray-900 border-transparent'
                "
                class="
                  flex-1
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  text-base
                  font-medium
                "
                aria-controls="tabs-1-panel-1"
                role="tab"
                type="button"
                @click="(openWomen = true), (openMen = false)"
              >
                Women
              </button>

              <!-- Selected: "text-indigo-600 border-indigo-600", Not Selected: "text-gray-900 border-transparent" -->
              <button
                id="tabs-1-tab-2"
                :class="
                  openMen
                    ? 'text-indigo-600 border-indigo-600'
                    : 'text-gray-900 border-transparent'
                "
                class="
                  flex-1
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  text-base
                  font-medium
                "
                aria-controls="tabs-1-panel-2"
                role="tab"
                type="button"
                @click="(openMen = true), (openWomen = false)"
              >
                Men
              </button>
            </div>
          </div>
          <div v-if="openMen">
            <TheMenMobileMenu />
          </div>
          <div v-if="openWomen">
            <TheWomenMobileMenu />
          </div>
          <div class="border-t border-gray-200 py-6 px-4 space-y-6">
            <div class="flow-root">
              <li
                class="-m-2 p-2 block font-medium text-gray-900 cursor-pointer"
                @click="clickedAbout"
              >
                About
              </li>
            </div>

            <div class="flow-root">
              <li
                class="-m-2 p-2 block font-medium text-gray-900 cursor-pointer"
                @click="clickedContact"
              >
                Contact
              </li>
            </div>
          </div>

          <div class="border-t border-gray-200 py-6 px-4 space-y-6">
            <div class="flow-root">
              <a
                href="#"
                class="-m-2 p-2 block font-medium text-gray-900"
                @click="goToRegistration"
              >
                Create an account
              </a>
            </div>
            <div class="flow-root">
              <a
                href="#"
                class="-m-2 p-2 block font-medium text-gray-900"
                @click="goToSignIn"
              >
                Sign in
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import IconBase from "@/components/Icons/IconBase";
import IconOutlineX from "@/components/Icons/IconOutlineX";
import TheWomenMobileMenu from "@/components/Navigation/MobileMenu2/TheWomenMobileMenu";
import TheMenMobileMenu from "@/components/Navigation/MobileMenu2/TheMenMobileMenu";

export default {
  name: "MobileMenu2",
  components: {
    TheWomenMobileMenu,
    TheMenMobileMenu,
    IconBase,
    IconOutlineX,
  },
  data() {
    return {
      openMen: false,
      openWomen: true,
    };
  },
  computed: {
    ...mapState(["openMobileMenu"]),
  },
  methods: {
    ...mapMutations(["toggleMobileMenu"]),
    clickedAbout() {
      if (this.$route.name !== "About") {
        this.$router.push({ name: "About" });
      }
      this.toggleMobileMenu();
    },
        clickedContact() {
      if (this.$route.name !== "Contact") {
        //console.log('kkk')
        this.$router.push({ name: "Contact" });
      }
      this.toggleMobileMenu();
    },
        goToRegistration() {
      if (this.$route.name !== "SignInAndRegistration") {
        this.$router.push({ name: "SignInAndRegistration" });
      }
      this.$store.dispatch("goToRegistration");
      this.toggleMobileMenu()
    },
        goToSignIn() {
      if (this.$route.name !== "SignInAndRegistration") {
        this.$router.push({ name: "SignInAndRegistration" });
      }
      this.$store.dispatch("goToSignIn");
      this.toggleMobileMenu()
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="bg-white">
    <!-- <transition name="mobile">
      <MobileMenu />
    </transition> -->
    <!-- Hero section -->
    <div class="relative bg-gray-900">
      <!-- Decorative image and overlay -->
      <div
        aria-hidden="true"
        class="absolute inset-0 overflow-hidden"
      >
        <img
          src="https://firebasestorage.googleapis.com/v0/b/fir-cart-c6633.appspot.com/o/home-page%2Fhome-page-01-hero-full-width.jpeg?alt=media&token=84d439a4-6224-4244-b2b2-126bdf6eaa0d"
          alt=""
          class="w-full h-full object-center object-cover"
        >
      </div>
      <div
        aria-hidden="true"
        class="absolute inset-0 bg-gray-900 opacity-50"
      />

      <!-- Navigation -->
      <!-- <TopNav /> -->
      <div
        class="
          relative
          max-w-3xl
          mx-auto
          py-32
          px-6
          flex flex-col
          items-center
          text-center
          sm:py-64
          lg:px-0
        "
      >
        <h1
          class="text-4xl font-extrabold tracking-tight text-white lg:text-6xl"
        >
          New arrivals are here
        </h1>
        <p class="mt-4 text-xl text-white">
          The new arrivals have, well, newly arrived. Check out the latest
          options from our winter small-batch premium collection while they're
          still in stock.
        </p> 
        <button
          class="
            mt-8
            inline-block
            bg-white
            border border-transparent
            rounded-md
            py-3
            px-8
            text-base
            font-medium
            text-gray-900
            hover:bg-gray-100
          "
          @click="goAndSetAllProducts"
        >
          View All Products
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//import TabPanel from "@/components/Navigation/TabPanel";
//import MobileMenu from "@/components/Navigation/MobileMenu";
//import FlyoutMenuDesktop from "@/components/Navigation/FlyoutMenuDesktop";
//import TopNav from "@/components/Navigation/TopNav";
export default {
  name: "TheHomeHero",
  components: {
    // MobileMenu,
    //FlyoutMenuDesktop,
    //TabPanel,
    // TopNav,
  },
  methods:{
    goAndSetAllProducts(){
      this.$router.push({path: "/Categories/all"})
      this.$store.dispatch('setShowAllProducts')
    }
  }
};
</script>

<style>
.mobile-enter-active,
.mobile-leave-active {
  @apply transition;
  @apply ease-in-out;
  @apply duration-300;
  @apply transform;
}
.mobile-enter,
.mobile-leave-to {
  @apply -translate-x-full;
}
.mobile-enter-to,
.mobile-leave {
  @apply translate-x-0;
}
</style>
<!--
      Off-canvas menu, show/hide based on off-canvas menu state.

      Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
        To: "translate-x-0"
      Leaving: "transition ease-in-out duration-300 transform"
        From: "translate-x-0"
        To: "-translate-x-full"
    -->

<template>
  <!-- 'Women' tab panel, show/hide based on tab state. -->
  <div
    id="tabs-1-panel-1"
    class="px-4 pt-10 pb-6 space-y-12"
    aria-labelledby="tabs-1-tab-1"
    role="tabpanel"
    tabindex="0"
  >
    <div class="grid grid-cols-1 items-start gap-y-10 gap-x-6">
      <div class="grid grid-cols-1 gap-y-10 gap-x-6">
        <div
          v-for="menu in allMenus"
          :key="menu.name"
        >
          <p class="font-medium text-gray-900">
            {{ menu.name }}
          </p>
          <div
            v-for="(item, index) in menu.currentMenu"
            :key="index"
          >
            <ul class="mt-6 space-y-6">
              <li v-if="!item.available">
                <p class="text-gray-500">
                  <span
                    v-tippy
                    content="Coming Soon"
                    class="cursor-not-allowed"
                  >
                    {{ item.text }}
                  </span>
                </p>
              </li>
              <li @click="clickedMobileMenuItem(item)">
                <router-link
                  v-if="item.available"
                  class="text-gray-500"
                  :to="`/categories/${item.text}`"
                >
                  {{ item.text }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
name:'TheWomenMobileMenu',
data(){
    return{
              allMenus: [
        {
          name: "Bags",
          currentMenu: [
            {
              text: "Totes",
              atClick: this.setShowTotes,
              available: true,
            },
            { text: "Hip Bags", atClick: this.setShowHipBags, available: true },
            { text: "Backpacks", atClick: this.setShowBackpacks, available:true },
            {
              text: "Travel Bags",
              atClick: this.setShowTravelBags, available:true
            },
          ],
        },
        {
          name: "Clothing",
          currentMenu: [
            { text: "Belts", atClick: this.setShowBelts, available:true },
            { text: "Socks", atClick: this.setShowSocks, available:true },
            { text: "Footies", atClick: this.setShowFooties, available:true },
          ],
        },
        {
          name: "Accessories",
          currentMenu: [
            { text: "Perfume", atClick: this.setShowPerfume, available:true },
            { text: "Watches", atClick: this.setShowWatches, available:true },
          ],
        },
        {
          name: "Beauty",
          currentMenu: [
            { text: "Masks", atClick: this.setShowMasks, available:true },
            { text: "Soap & Shampoo", atClick: this.setShowSoapShampoo, available:true },
            { text: "Gifts", atClick: this.setShowGifts },
            { text: "Significant Other", atClick: this.setShowSigOther },
          ],
        },
      ],
    }
},
  methods: {
          clickedMobileMenuItem(item) {
      item.atClick();
      this.toggleMobileMenu();
    },
    ...mapActions([
      "setShowTotes",
      "setShowBackpacks",
      "setShowTravelBags",
      "setShowHipBags",
      "setShowBelts",
      "setShowSocks",
      "setShowFooties",
      "setShowPerfume",
      "setShowWatches",
      "setShowMasks",
      "setShowSoapShampoo",
      "setShowGifts",
      "setShowSigOther",
    ]),
        ...mapMutations(["toggleMobileMenu"]),
  },
}
</script>

<style>

</style>
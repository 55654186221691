<template>
  <main class="max-w-7xl mx-auto sm:pt-16 sm:px-6 lg:px-8">
    <div class="max-w-2xl mx-auto lg:max-w-none">
      <!-- Product -->
      <div class="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
        <!-- Image gallery -->
        <div class="flex flex-col-reverse">
          <!-- Image selector -->

          <div class="w-full aspect-w-1 aspect-h-1">
            <!-- Tab panel, show/hide based on tab state. -->
            <div
              id="tabs-2-panel-1"
              aria-labelledby="tabs-2-tab-1"
              role="tabpanel"
              tabindex="0"
            >
              <div
                v-for="product in currentProduct"
                :key="product.name"
              >
                <div class="flex justify-center items-start bg-white h-144">
                  <img
                    :src="product.images[0]"
                    alt=""
                    class="h-5/6 sm:rounded-lg"
                  >
                </div>
              </div>
            </div>
            <!-- More images... -->
          </div>
        </div>

        <!-- Product info -->
        <div class="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
          <div
            v-for="product in currentProduct"
            :key="product.name"
          >
            <h1 class="text-3xl font-extrabold tracking-tight text-gray-900">
              {{ product.name }}
            </h1>
          </div>

          <div class="mt-3">
            <h2 class="sr-only">
              Product information
            </h2>
            <div
              v-for="product in currentProduct"
              :key="product.name"
            >
              <p class="text-3xl text-gray-900">
                <span>$</span>{{ product.price }}
              </p>
            </div>
          </div>

          <div class="mt-6">
            <h3 class="sr-only">
              Description
            </h3>

            <div class="text-base text-gray-700 space-y-6">
              <div
                v-for="product in currentProduct"
                :key="product.name"
              >
                <p>{{ product.description }}</p>
              </div>
            </div>
          </div>
          <TheAddToCartBtn />

          <section
            aria-labelledby="details-heading"
            class="mt-12"
          >
            <h2
              id="details-heading"
              class="sr-only"
            >
              Additional details
            </h2>

            <div class="border-t divide-y divide-gray-200">
              <div>
                <h3>
                  <!-- Expand/collapse question button -->
                  <button
                    type="button"
                    class="
                      group
                      relative
                      w-full
                      py-6
                      flex
                      justify-between
                      items-center
                      text-left
                    "
                    aria-controls="disclosure-1"
                    aria-expanded="false"
                  >
                    <!-- Open: "text-indigo-600", Closed: "text-gray-900" -->
                    <span
                      class="text-sm font-medium"
                      :class="
                        showProductFeatures
                          ? 'text-indigo-600'
                          : 'text-gray-900'
                      "
                    >
                      Features
                    </span>
                    <span class="ml-6 flex items-center">
                      <button
                        v-if="!showProductFeatures"
                        @click="toggleProductFeatures"
                      >
                        <IconBase
                          view-box="0 0 24 24"
                          class="
                            block
                            h-6
                            w-6
                            text-gray-400
                            group-hover:text-gray-500
                          "
                        ><IconPlus /></IconBase>
                      </button>
                      <button
                        v-if="showProductFeatures"
                        @click="toggleProductFeatures"
                      >
                        <IconBase
                          view-box="0 0 24 24"
                          class="
                            h-6
                            w-6
                            text-indigo-400
                            group-hover:text-indigo-500
                          "
                        ><IconMinus /></IconBase>
                      </button>
                    </span>
                  </button>
                </h3>
                <div
                  v-if="showProductFeatures"
                  id="disclosure-1"
                  class="pb-6 prose prose-sm"
                >
                  <ul role="list">
                    <div
                      v-for="product in currentProduct"
                      :key="product.name"
                    >
                      <li
                        v-for="(feature, index) in product.features"
                        :key="index"
                      >
                        {{ feature }}
                      </li>
                    </div>
                  </ul>
                </div>
              </div>

              <!-- More sections... -->
            </div>
          </section>
        </div>
      </div>
      <!-- 
      <TheCustomersAlsoBought /> -->
    </div>
  </main>
</template>

<script>
import IconBase from "@/components/Icons/IconBase";
import IconMinus from "@/components/Icons/IconMinus";
import IconPlus from "@/components/Icons/IconPlus";
import TheAddToCartBtn from "@/components/ui/TheAddToCartBtn";
//import TheCustomersAlsoBought from "@/components/Products/TheCustomersAlsoBought";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    // TheCustomersAlsoBought,
    IconBase,
    IconMinus,
    IconPlus,
    TheAddToCartBtn,
  },
    computed: {
    ...mapState(["products", "showProductFeatures"]),
    currentProduct() {
      let route = this.$route.params.name;
      return this.products.filter((pro) => {
        if (pro.name === route) {
          return pro.name;
        }
      });
    },
  },
  methods: {
    ...mapMutations(["toggleProductFeatures"]),
  },
};
</script>

<style></style>

<template>
  <div>
    <!-- <TheHeader /> -->
    <div
      class="
        grid grid-cols-12
        px-8
        bg-gray-50
        divide-y divide-gray-200
        max-w-4xl
        mx-auto
      "
    >
      <p
        class="
          col-span-4
          px-6
          py-3
          text-left text-xs
          font-medium
          text-gray-500
          uppercase
          tracking-wider
        "
      >
        Name
      </p>
      <p
        class="
          col-span-2
          px-6
          py-3
          text-left text-xs
          font-medium
          text-gray-500
          uppercase
          tracking-wider
        "
      >
        Category
      </p>
      <p
        class="
          col-span-2
          px-6
          py-3
          text-left text-xs
          font-medium
          text-gray-500
          uppercase
          tracking-wider
        "
      >
        Brand
      </p>
      <p
        class="
          px-6
          py-3
          text-left text-xs
          font-medium
          text-gray-500
          uppercase
          tracking-wider
        "
      >
        Price
      </p>

      <p
        class="
          px-6
          py-3
          text-left text-xs
          font-medium
          text-gray-500
          uppercase
          tracking-wider
        "
      >
        Images
      </p>
      <p
        class="
          px-6
          py-3
          text-left text-xs
          font-medium
          text-gray-500
          uppercase
          tracking-wider
        "
      >
        Link
      </p>
    </div>
    <div class="pros max-w-4xl divide-y divide-gray-200 mx-auto">
      <Accordion2
        v-for="(pro, i) in products"
        :key="i"
        :pro="pro"
        :index="i"
        :open="pro.open"
        @toggle-open="toggleOpen"
      />
    </div>
  </div>
</template>

<script>
import Accordion2 from "@/Pages/Admin/Products/ProductsTable/Accordion2";
// import TheHeader from '@/Pages/Admin/Products/ProductsTable/TheHeader'
export default {
  name: "ProductsTable",
  components: {
    Accordion2,
    // TheHeader,
  },
  computed: {
    products: {
      set() {},
      get() {
        return this.$store.state.products;
      },
    },
  },
  methods: {
    toggleOpen: function (index) {
      this.products = this.products.map((pro, i) => {
        if (index === i) {
          pro.open = !pro.open;
        } else {
          pro.open = false;
        }
        return pro;
      });
    },
  },
};
</script>

<style>
.pro {
  display: block;
  width: 100%;
  padding: 4px;
  background-color: #fff;
}
.pro .question {
  position: relative;
  color: #3c3c3c;
  font-size: 20px;
  transition: all 0.4s linear;
}
.pro .question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%) rotate(0deg);
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.2s linear;
}
.pro.open .question {
  margin-bottom: 15px;
}
.pro.open .question::after {
  transform: translateY(-50%) rotate(90deg);
}
.pro .answer {
  color: #3c3c3c;
  font-size: 18px;
  opacity: 0;
  max-height: 0px;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}
.pro.open .answer {
  opacity: 1;
  max-height: 100%;
}
</style>
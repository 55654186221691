//import firebase from "firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
//import { initializeApp } from "firebase/app"
  
const firebaseConfig = {
  apiKey: "AIzaSyAe2t5FoXNU8y_S2jwA2JBYaZE66a0Gk8A",
  authDomain: "fir-cart-c6633.firebaseapp.com",
  projectId: "fir-cart-c6633",
  storageBucket: "fir-cart-c6633.appspot.com",
  messagingSenderId: "299026296355",
  appId: "1:299026296355:web:6b30e6e965dc6172ee0b96",
};

//export auth

// init firebase and add to var
const firebaseApp = firebase.initializeApp(firebaseConfig);
//const firebaseApp = initializeApp(firebaseConfig)
//export db
export default firebaseApp.firestore();

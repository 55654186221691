<template>
  <!--
  This example requires require('@tailwindcss/forms'),
-->
  <div
    class="
      min-h-screen
      flex
      items-center
      justify-center
      bg-gray-50
      py-12
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <TheDrops class="mx-auto h-16 w-auto" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Admin
        </h2>
      </div>
      <form
        class="mt-8 space-y-6"
        @submit.prevent="login"
      >
        <input
          type="hidden"
          name="remember"
          value="true"
        >
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label
              for="email-address"
              class="sr-only"
            >Email address</label>
            <input
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              "
              placeholder="Email address"
            >
          </div>
          <div>
            <label
              for="password"
              class="sr-only"
            >Password</label>
            <input
              id="password"
              v-model="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-b-md
                focus:outline-none
                focus:ring-indigo-500
                focus:border-indigo-500
                focus:z-10
                sm:text-sm
              "
              placeholder="Password"
            >
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              hover:bg-indigo-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <IconBase
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                view-box="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              ><IconSolidLockClosed /></IconBase>
            </span>
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import IconBase from "@/components/Icons/IconBase";
import IconSolidLockClosed from "@/components/Icons/IconSolidLockClosed";
import TheDrops from "@/components/Icons/TheDrops";
export default {
  name: "Admin",
  components: {
    IconBase,
    IconSolidLockClosed,
    TheDrops,
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      if (this.email && this.password) {
        console.log(this.email, this.password);
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.email, this.password)
          // firebase
          //   .auth()
          //   .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            console.log("whoof");
            this.$router.push("/Admin/Dashboard");
          })
          .catch((error) => {
            this.feedback = error.message;
            console.log("www", error);
          });
      } else {
        console.log("flopped");
        //  this.feedback = "בבקשה למלאות שני השדות";
      }
    },
  },
};
</script>

<style></style>

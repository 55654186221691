<template>
  <div>
    <TheHomeHero />
    <CategorySection />
    <FeaturedSection />
  </div>
</template>

<script>
import TheHomeHero from "@/components/Navigation/TheHomeHero";
import CategorySection from "@/components/ecommerce/CategorySection";
import FeaturedSection from "@/components/ecommerce/FeaturedSection";
export default {
  name: "Home",
  components: {
    TheHomeHero,
    CategorySection,
    FeaturedSection,
  },
}
</script>

import Vue from "vue";
import Vuex from "vuex";
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';
import { getFirestore, collection, getDocs } from "firebase/firestore";
import db from "@/firebase/init";
import * as Cookies from "js-cookie";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const getDefauleState = () => {
  return {
    cart: [],
    products: [],
    currentCat: null,
  };
};

const toggle = (key) => (state) => {
  state[key] = !state[key];
};
const set = (key) => (state, val) => {
  state[key] = val;
};

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    showCart: false,
    cart: [],
    currentQuickview: "",
    currentCategory: "All Products",
    products: [],
    openMobileMenu: false,
    mobileFilterMenu: false,
    showWomenTab: true,
    showMenTab: false,
    showMenDesktopTab: false,
    showWomenDesktopTab: false,
    showQuickview: false,
    signIn: true,
    registration: false,
    showProductFeatures: false,
    currentCat: null,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefauleState());
    },
    decrementCart(state, val) {
      const cartItem = state.cart.find((item) => item.id === val);
      cartItem.quantity--;
    },
    cartIncrease(state, val) {
      const cartItem = state.cart.find((item) => item.id === val);
      cartItem.quantity++;
    },
    cartRemove(state, val) {
      const cartItem = state.cart.find((item) => item.id === val);
      const index = state.cart.indexOf(cartItem);
      state.cart.splice(index, 1);
    },
    removeItemFromCart(state) {
      const product = state.cart.find(
        (item) => item.id === state.currentQuickview.id
      );
      const index = state.cart.indexOf(product);
      state.cart.splice(index, 1);
    },
    increaseQuantity(state) {
      const product = state.cart.find(
        (item) => item.id === state.currentQuickview.id
      );
      product.quantity++;
    },
    decrementQuantity(state) {
      const product = state.cart.find(
        (item) => item.id === state.currentQuickview.id
      );
      product.quantity--;
    },
    addItemToCart(state, { product }) {
      state.cart.push({
        id: product.id,
        name: product.name,
        price: product.price,
        image: product.images[0],
        brand: product.brand,
        quantity: 1,
      });
      console.log(state.cart);
    },
    setQuickviewProduct(state, val) {
      state.currentQuickview = val;
      // Vue.set(val, "quantity", 1);
    },
    toggleQuickview(state) {
      state.showQuickview = !state.showQuickview;
      if (state.showQuickview === true) {
        document.body.style.overflow = "hidden";
        document.body.style.height = "100vh";
      } else {
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      }
    },
    toggleMobileMenu(state) {
      state.openMobileMenu = !state.openMobileMenu;
      if (state.openMobileMenu === true) {
        document.body.style.overflow = "hidden";
        document.body.style.height = "100vh";
      } else {
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      }
    },
    consoleQuicker(state) {
      console.log(state.currentQuickview, "helloa");
    },
    toggleShowCart(state) {
      state.showCart = !state.showCart;
      if (state.showCart === true) {
        document.body.style.overflow = "hidden";
        document.body.style.height = "100vh";
      } else {
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
      }
    },
    showMenDesktopTab: set("showMenDesktopTab"),
    showWomenDesktopTab: set("showWomenDesktopTab"),
    toggleProductFeatures: toggle("showProductFeatures"),
    toggleMobileFilterMenu: toggle("mobileFilterMenu"),
    chooseWomenTab: set("showWomenTab"),
    chooseMenTab: set("showMenTab"),
    setCurrentCategory: set("currentCategory"),
    setSignIn: set("signIn"),
    setRegistration: set("registration"),
    setCurrentCat: set("currentCat"),
  },
  getters: {
    mensLaptop: (state) => {
      return state.products.filter((item) => {
        if (item.category === "mensLaptop") {
          return item;
        }
      });
    },
    mensMessenger: (state) => {
      return state.products.filter((item) => {
        if (item.category === "mensMessenger") {
          return item;
        }
      });
    },
    soapShampoo: (state) => {
      return state.products.filter((item) => {
        if (item.category === "soapShampoo") {
          return item;
        }
      });
    },
    masks: (state) => {
      return state.products.filter((item) => {
        if (item.category === "masks") {
          return item;
        }
      });
    },
    watches: (state) => {
      return state.products.filter((item) => {
        if (item.category === "watches") {
          return item;
        }
      });
    },
    perfume: (state) => {
      return state.products.filter((item) => {
        if (item.category === "perfume") {
          return item;
        }
      });
    },
    footies: (state) => {
      return state.products.filter((item) => {
        if (item.category === "footies") {
          return item;
        }
      });
    },
    socks: (state) => {
      return state.products.filter((item) => {
        if (item.category === "socks") {
          return item;
        }
      });
    },
    belts: (state) => {
      return state.products.filter((item) => {
        if (item.category === "belts") {
          return item;
        }
      });
    },
    totes: (state) => {
      return state.products.filter((item) => {
        if (item.category === "totes") {
          return item;
        }
      });
    },
    backpacks: (state) => {
      return state.products.filter((item) => {
        if (item.category === "backpacks") {
          return item;
        }
      });
    },
    travelBags: (state) => {
      return state.products.filter((item) => {
        if (item.category === "travelBags") {
          return item;
        }
      });
    },
    hipBags: (state) => {
      return state.products.filter((item) => {
        if (item.category === "hipBags") {
          return item;
        }
      });
    },
    laptopSleeves: (state) => {
      return state.products.filter((item) => {
        if (item.category === "laptopSleeves") {
          return item;
        }
      });
    },
    mensBackpacks: (state) => {
      return state.products.filter((item) => {
        if (item.category === "mensBackpacks") {
          return item;
        }
      });
    },
  },
  actions: {
    cartDecrease(context, val) {
      const cartItem = context.state.cart.find((item) => item.id === val);
      if (cartItem.quantity > 1) {
        context.commit("decrementCart", cartItem.id);
      } else {
        context.commit("cartRemove", cartItem.id);
      }
    },
    decreaseQuantity(context) {
      const product = context.state.cart.find(
        (item) => item.id === context.state.currentQuickview.id
      );
      if (product.quantity > 1) {
        context.commit("decrementQuantity");
      } else {
        context.commit("removeItemFromCart");
      }
    },
    addToCart(context, product) {
      context.commit("addItemToCart", {
        id: product.id,
        product,
      });
    },
    toggleQuickview(context) {
      context.commit("toggleQuickview");
    },
    async getProducts(context) {
      this.state.products = [];
      const querySnapshot = await getDocs(collection(db, "tailProducts"));
      querySnapshot.forEach((doc) => {
        this.state.products.push({ ...doc.data(), id: doc.id });
      });
      this.state.products.map((pro) => {
        Vue.set(pro, "open", false);
      });
      context.commit("setCurrentCat", this.state.products);
    },
    choosingMenDesktopTab(context) {
      context.commit("showMenDesktopTab", true);
      context.commit("showWomenDesktopTab", false);
    },
    choosingWomenDesktopTab(context) {
      context.commit("showWomenDesktopTab", true);
      context.commit("showMenDesktopTab", false);
    },
    exWomenDesktopTab(context) {
      context.commit("showWomenDesktopTab", false);
    },
    exMenDesktopTab(context) {
      context.commit("showMenDesktopTab", false);
    },
    choosingMenTab(context) {
      context.commit("chooseWomenTab", false);
      context.commit("chooseMenTab", true);
    },
    setShowMensMessenger(context) {
      context.commit("setCurrentCategory", "Messenger Bags");
      context.commit("setCurrentCat", this.getters.mensMessenger);
    },
    setShowAllProducts(context) {
      context.commit("setCurrentCategory", "All Products");
      context.commit("setCurrentCat", this.state.products);
    },
    setShowMensLaptop(context) {
      context.commit("setCurrentCategory", "Mens Laptop Bag");
      context.commit("setCurrentCat", this.getters.mensLaptop);
    },
    setShowTotes(context) {
      context.commit("setCurrentCategory", "Totes");
      context.commit("setCurrentCat", this.getters.totes);
    },
    setShowBackpacks(context) {
      context.commit("setCurrentCategory", "Backpacks");
      context.commit("setCurrentCat", this.getters.backpacks);
    },
    setShowTravelBags(context) {
      context.commit("setCurrentCategory", "Travel Bags");
      context.commit("setCurrentCat", this.getters.travelBags);
    },
    setShowHipBags(context) {
      context.commit("setCurrentCategory", "Hip Bags");
      context.commit("setCurrentCat", this.getters.hipBags);
    },
    setShowLaptopSleeves(context) {
      context.commit("setCurrentCategory", "Laptop Sleeves");
      context.commit("setCurrentCat", this.getters.laptopSleeves);
    },
    setShowBelts(context) {
      context.commit("setCurrentCategory", "Belts");
      context.commit("setCurrentCat", this.getters.belts);
    },
    setShowSocks(context) {
      context.commit("setCurrentCategory", "Socks");
      context.commit("setCurrentCat", this.getters.socks);
    },
    setShowFooties(context) {
      context.commit("setCurrentCategory", "Footies");
      context.commit("setCurrentCat", this.getters.footies);
    },
    setShowPerfume(context) {
      context.commit("setCurrentCategory", "Perfume");
      context.commit("setCurrentCat", this.getters.perfume);
    },
    setShowWatches(context) {
      context.commit("setCurrentCategory", "Watches");
      context.commit("setCurrentCat", this.getters.watches);
    },
    setShowMasks(context) {
      context.commit("setCurrentCategory", "Masks");
      context.commit("setCurrentCat", this.getters.masks);
    },
    setShowSoapShampoo(context) {
      context.commit("setCurrentCategory", "Soap & Shampoo");
      context.commit("setCurrentCat", this.getters.soapShampoo);
    },
    setShowGifts(context) {
      context.commit("setCurrentCategory", "Gifts");
      context.commit("setCurrentCat", this.getters.gifts);
    },
    setShowSigOther(context) {
      context.commit("setCurrentCategory", "Significant Other");
      context.commit("setCurrentCat", this.getters.sigOther);
    },
    setShowMBackpacks(context) {
      context.commit("setCurrentCategory", "Backpacks");
      context.commit("setCurrentCat", this.getters.mensBackpacks);
    },
    goToSignIn(context) {
      context.commit("setSignIn", true);
      context.commit("setRegistration", false);
    },
    goToRegistration(context) {
      context.commit("setSignIn", false);
      context.commit("setRegistration", true);
    },
  },
  modules: {},
});

<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 511.999 511.999"
    style="enable-background: new 0 0 511.999 511.999"
    xml:space="preserve"
  >
    <path
      style="fill: #9cd7f2"
      d="M375.297,32.758L188.928,74.363c-12.286,2.742-13.974,19.119-2.677,24.651l35.132,17.206
	c75.453,36.952,120.795,94.336,120.687,94.233c49.102,58.255,145.813,39.78,166.45-42.784
	C528.796,88.768,457.35,14.44,375.297,32.758z"
    />
    <path
      style="fill: #71c5db"
      d="M377.761,136.318c0.138,0.056-63.221-36.473-146.993-42.876l-39.004-2.982
	c-6.071-0.464-10.247-4.417-11.77-9.174c-2.919,6.029-1.053,14.148,6.257,17.728l35.132,17.205
	c75.453,36.952,120.795,94.336,120.687,94.233c49.103,58.255,145.813,39.78,166.45-42.785c6.04-23.501,3.939-46.595-4.154-66.958
	C479.672,143.037,424.833,161.506,377.761,136.318z"
    />
    <path
      style="fill: #9cd7f2"
      d="M426.164,336.005l-113.298-61.554c-9.821-5.335-20.686,4.179-16.997,14.778l6.632,19.048
	c20.031,57.536,20.331,107.131,20.227,104.877c2.511,54.516,63.546,87.976,110.496,57.09
	C482.436,437.419,478.162,364.255,426.164,336.005z"
    />
    <path
      style="fill: #71c5db"
      d="M364.813,401.193c0.661,2.157-11.977-45.8-45.703-96.538l-11.166-16.797
	c-3.392-5.103-2.496-10.968,0.777-14.803c-8.433-1.242-16.024,7.061-12.851,16.175l6.632,19.048
	c20.031,57.536,20.331,107.131,20.227,104.877c2.511,54.516,63.546,87.976,110.496,57.09c8.831-5.891,15.93-13.085,21.35-21.08
	C418.648,460.197,376.947,440.749,364.813,401.193z"
    />
    <path
      style="fill: #9cd7f2"
      d="M187.437,289.391c-43.33-36.721-91.727-73.882-134.55-152.159l0,0
	c-8.375-12.226-27.158-7.885-28.865,6.84L0.823,344.098c-10.264,88.502,77.395,155.139,158.46,124.594
	C234.564,440.337,249.779,340.23,187.437,289.391z"
    />
    <path
      style="fill: #71c5db"
      d="M66.074,339.104L38.601,139.619c-0.491-3.567,0.152-6.787,1.559-9.486
	c-7.576-0.24-15.088,4.88-16.139,13.937L0.823,344.097c-10.264,88.502,77.395,155.139,158.46,124.594
	c16.885-6.359,30.737-16.336,41.382-28.55C139.399,449.029,75.556,407.944,66.074,339.104z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>

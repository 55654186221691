<template>
  <header class="relative z-10 bg-gray-900">
    <nav aria-label="Top">
      <!-- Secondary navigation -->
      <div class="backdrop-blur-md backdrop-filter bg-opacity-10 bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div>
            <div class="h-16 flex items-center justify-between">
              <!-- Logo (lg+) -->
              <div class="hidden lg:flex-1 lg:flex lg:items-center">
                <router-link to="/">
                  <span class="sr-only">Workflow</span>
                  <TheDrops class="h-8 w-auto" />
                </router-link>
              </div>

              <div class="hidden h-full lg:flex -ml-72">
                <!-- Flyout menus -->
                <div class="px-4 bottom-0 inset-x-0">
                  <div class="h-full flex justify-center space-x-8">
                    <div
                      v-click-outside="closeFlyMenu"
                      class="flex"
                    >
                      <div class="relative flex">
                        <button
                          id="men-btn"
                          type="button"
                          class="
                            relative
                            z-10
                            flex
                            items-center
                            justify-center
                            transition-colors
                            ease-out
                            duration-200
                            text-sm
                            font-medium
                            text-white
                          "
                          aria-expanded="false"
                          @click="toggleFlyMenu"
                        >
                          Men
                          <span
                            class="
                              absolute
                              -bottom-px
                              inset-x-0
                              h-0.5
                              transition
                              ease-out
                              duration-200
                            "
                            :class="showMenDesktopTab ? 'bg-white' : ''"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                      <transition>
                        <div v-if="showMenDesktopTab">
                          <TheMenFlyoutMenu />
                        </div>
                      </transition>
                    </div>

                    <!-- <button
                      class="text-white"
                      @click="sound = !sound"
                    >
                      disable sound
                    </button> -->
                    <div
                      v-click-outside="closeFlyMenu"
                      class="flex"
                    >
                      <div class="relative flex">
                        <button
                          id="women-btn"
                          type="button"
                          class="
                            relative
                            z-10
                            flex
                            items-center
                            justify-center
                            transition-colors
                            ease-out
                            duration-200
                            text-sm
                            font-medium
                            text-white
                          "
                          aria-expanded="false"
                          @click="toggleFlyMenu"
                        >
                          Women
                          <span
                            class="
                              absolute
                              -bottom-px
                              inset-x-0
                              h-0.5
                              transition
                              ease-out
                              duration-200
                            "
                            :class="showWomenDesktopTab ? 'bg-white' : ''"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                      <transition>
                        <div v-if="showWomenDesktopTab">
                          <TheWomenFlyoutMenu />
                        </div>
                      </transition>
                    </div>

                    <router-link
                      class="flex items-center text-sm font-medium text-white"
                      to="/about"
                    >
                      About
                    </router-link>
                    <router-link
                      class="flex items-center text-sm font-medium text-white"
                      to="/contact"
                    >
                      Contact
                    </router-link>
                  </div>
                </div>
              </div>
              <TheMobileMenuAndSearch />

              <!-- Logo (lg-) -->
              <span class="sr-only">Royal Drop</span>
              <router-link
                to="/"
                class="lg:hidden"
              >
                <span class="sr-only">Workflow</span>
                <TheDrops class="h-8 w-auto" />
              </router-link>
              <div class="flex-1 flex items-center justify-end space-x-8">
                <button
                  class="text-white"
                  @click="sound = !sound"
                >
                  disable sound
                </button>
                <a
                  href="#"
                  class="
                    text-sm
                    font-medium
                    text-white
                    hover:text-gray-100
                    hidden
                    lg:block
                  "
                  @click="goToSignIn"
                >
                  Sign in
                </a>
                <a
                  href="#"
                  class="
                    text-sm
                    font-medium
                    text-white
                    hover:text-gray-100
                    hidden
                    lg:block
                  "
                  @click="goToRegistration"
                >
                  Create an account
                </a>
                <div
                  class="ml-4 flow-root lg:ml-8"
                  @click="toggleShowCart"
                >
                  <a
                    href="#"
                    class="group -m-2 p-2 flex items-center"
                  >
                    <IconBase
                      class="flex-shrink-0 h-6 w-6 text-white"
                      view-box="0 0 24 24"
                      aria-hidden="true"
                    ><IconOutlineShoppingBag /></IconBase>
                    <span class="ml-2 text-sm font-medium text-white">{{
                      cart.length
                    }}</span>
                    <span class="sr-only">items in cart, view bag</span>
                  </a>
                </div>
                <div class="flex items-center lg:ml-8" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import uiClick from '@/assets/sound/ui-click.wav'
import openNav from '@/assets/sound/open-nav.mp3'
import TheDrops from "@/components/Icons/TheDrops";
import TheMobileMenuAndSearch from "@/components/Navigation/TopNav/TheMobileMenuAndSearch";
import IconBase from "@/components/Icons/IconBase";
import IconOutlineShoppingBag from "@/components/Icons/IconOutlineShoppingBag";
import TheWomenFlyoutMenu from "@/components/Navigation/TheWomenFlyoutMenu";
import TheMenFlyoutMenu from "@/components/Navigation/TheMenFlyoutMenu";
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  components: {
    TheMobileMenuAndSearch,
    TheDrops,
    TheWomenFlyoutMenu,
    TheMenFlyoutMenu,
    IconBase,
    IconOutlineShoppingBag,
  },
  data(){
    return{
      sound: true,
    source:  '@/assets/sound/open-nav.mp3'
    }
  },
  computed: {
    ...mapState(["cart", "showMenDesktopTab", "showWomenDesktopTab"]),
  },
  methods: {
    ...mapActions([
      "exWomenDesktopTab",
      "exMenDesktopTab",
      "choosingMenDesktopTab",
      "choosingWomenDesktopTab",
    ]),
    ...mapMutations(["toggleShowCart"]),
    closeFlyMenu(e) {
      let exclude = e.target.id;
      if (this.showMenDesktopTab && exclude !== "men-btn") {
        this.exMenDesktopTab();
      } else if (this.showWomenDesktopTab && exclude !== "women-btn") {
        this.exWomenDesktopTab();
      } else {
        return;
      }
    },
    turnOff(){

    },
    toggleFlyMenu(e) {
      // let y = new Audio()
      // let x = document.getElementById('myAudio')
      // y.play(x)
      if (this.sound){
         let snd = new Audio(uiClick)
      snd.play() 
      }
    
      let clickedBtn = e.target.id;
      if (clickedBtn === "men-btn") {
        if (this.showMenDesktopTab) {
          this.exMenDesktopTab();
        } else {
          this.choosingMenDesktopTab();
        }
      } else if (clickedBtn === "women-btn") {
        if (this.showWomenDesktopTab) {
          this.exWomenDesktopTab();
        } else {
          this.choosingWomenDesktopTab();
        }
      } else {
        return;
      }
    },
    goToRegistration() {
      if (this.$route.name !== "SignInAndRegistration") {
        this.$router.push({ name: "SignInAndRegistration" });
      }
      this.$store.dispatch("goToRegistration");
    },
    goToSignIn() {
      if (this.$route.name !== "SignInAndRegistration") {
        this.$router.push({ name: "SignInAndRegistration" });
      }
      this.$store.dispatch("goToSignIn");
    },
  },
};
</script>

<style scoped>
.v-enter-active {
  @apply transition;
  @apply ease-out;
  @apply duration-500;
}
.v-enter,
.v-leave-to {
  @apply opacity-0;
}
.v-enter-to,
.v-leave {
  @apply opacity-100;
}
.v-leave-active {
  @apply transition;
  @apply ease-in;
  @apply duration-150;
}
</style>
